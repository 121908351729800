import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const authToken = localStorage.getItem('authToken'); // Retrieve the token from localStorage

    if (!authToken) {
        localStorage.setItem('lastVisitedPath', location.pathname);
        return <Navigate to="/login" />;
    }

    return children; // Render children if authenticated
};

export default PrivateRoute;
