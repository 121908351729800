import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FaFolderOpen } from 'react-icons/fa'; // Importing the folder icon from react-icons


const SectionPage = () => {
    const { name } = useParams();

    const [jsonData, setJsonData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [guid, setGuid] = useState(name);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isProcessed, setIsProcessed] = useState(false); // Track if the file has been successfully processed

    const baseUrl = process.env.REACT_APP_PROCESSOR_API;

    const jsonUrl = guid ? `${baseUrl}/section/file/${guid}.json` : '';
    const dxfUrl = guid ? `${baseUrl}/section/file/${guid}.dxf` : '';
    const pngUrl = guid ? `${baseUrl}/section/file/${guid}.png` : '';

    // Fetch JSON data if `guid` exists
    useEffect(() => {
        if (jsonUrl) {
            setErrorMessage(null);
            fetch(jsonUrl)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Error fetching JSON data');
                    }
                    return response.json();
                })
                .then((data) => setJsonData(data))
                .catch((error) => {
                    console.error('Error fetching JSON:', error);
                    setErrorMessage('Something went wrong while fetching the JSON data.');
                    setIsProcessed(false); // Show steps again if error occurs
                });
        }
    }, [jsonUrl]);

    // Handle file upload and call the API
    const handleFileUpload = async (file) => {
        setIsLoading(true);
        setErrorMessage(null); // Clear previous errors

        const formData = new FormData();
        formData.append('files', file);

        try {
            const response = await axios.post(`${baseUrl}/section/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                maxBodyLength: Infinity,
            });

            const newGuid = response.data.guid;
            setGuid(newGuid);
            setIsProcessed(true); // Set processed to true
            setIsLoading(false);
        } catch (error) {
            console.error('Error uploading file:', error);
            setErrorMessage('Something went wrong during file upload. Please try again.');
            setIsProcessed(false); // Show steps again if error occurs
            setIsLoading(false);
        }
    };

    // Drag and Drop file handling
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            handleFileUpload(acceptedFiles[0]);
        }
    }, []);

    // Handle file change for file input (Upload button)
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            handleFileUpload(selectedFile); // Trigger file upload when a file is selected
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.json,.dxf,.png,.jpg,.jpeg',
        multiple: false,
    });

    const renderJsonData = () => {
        if (!jsonData) return null;

        if (Array.isArray(jsonData)) {
            return jsonData.map((item, index) => {
                const key = Object.keys(item)[0];
                const value = Object.values(item)[0];
                return (
                    <tr key={index} className="border-t">
                        <td className="px-4 py-2 text-sm text-gray-600">{key}</td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                            {typeof value === 'object'
                                ? Object.entries(value).map(([subKey, subValue]) => (
                                    <div key={subKey}>
                                        <strong>{subKey}: </strong>{subValue}
                                    </div>
                                ))
                                : value}
                        </td>
                    </tr>
                );
            });
        } else if (typeof jsonData === 'object') {
            return Object.entries(jsonData).map(([key, value], index) => (
                <tr key={index} className="border-t">
                    <td className="px-4 py-2 text-sm text-gray-600">{key}</td>
                    <td className="px-4 py-2 text-sm text-gray-600">
                        {typeof value === 'object'
                            ? Object.entries(value).map(([subKey, subValue]) => (
                                <div key={subKey}>
                                    <strong>{subKey}: </strong>{subValue}
                                </div>
                            ))
                            : value}
                    </td>
                </tr>
            ));
        } else {
            return (
                <tr className="border-t">
                    <td className="px-4 py-2 text-sm text-gray-600">Data</td>
                    <td className="px-4 py-2 text-sm text-gray-600">{jsonData}</td>
                </tr>
            );
        }
    };

    return (
        <div className="min-h-screen p-6 flex flex-col bg-gray-50">
            <div className="mb-8 flex items-center space-x-4">
                <label htmlFor="file-upload"
                       className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 cursor-pointer transition duration-300 flex items-center space-x-2">
                    <FaFolderOpen className="h-5 w-5"/> {/* Folder icon */}
                    <span>Upload</span>
                </label>
                <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange} // Automatically trigger file upload when file is selected
                />

            </div>

            <div className='items-center'>

                {!isProcessed && !isLoading && (
                    <div>
                        <div
                            {...getRootProps()}
                            className={`w-full md:w-3/4 lg:w-1/2  border-2 border-dashed rounded-lg p-4 mb-6 flex items-center justify-center ${isDragActive ? 'border-blue-600 bg-blue-50' : 'border-gray-400 m-auto'}`}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p className="text-blue-600">Drop the file here...</p>
                            ) : (

                                <p className="text-gray-600 text-center">
                                    <br/>
                                    Drag 'n' drop a file here, or click to select a file
                                    <br/>
                                    <br/>This app calculates the mass properties of a given section.
                                    <br/> Ensure the section is a closed curve.
                                </p>
                            )}
                        </div>

                        <div className="flex flex-col items-center">
                            {errorMessage && (
                                <div className="mb-4 text-red-600">
                                    {errorMessage}
                                </div>
                            )}
                            <a
                                href="http://127.0.0.1:8000/api/section/file/facadeoption_demo.dxf"
                                download="facadeoption_demo.dxf"
                            >
                                <button
                                    className="px-6 py-2 bg-gray-400 text-white font-semibold rounded-lg shadow-md hover:bg-gray-500 transition duration-300"
                                >
                                    Download Demo DXF
                                </button>
                            </a>

                        </div>

                    </div>
                )}

                {/* Loading Spinner */}
                {isLoading && (
                    <div className="mb-4 flex flex-col items-center justify-center space-y-2">
                        {/* Spinner */}
                        <div
                            className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>

                        {/* Uploading text */}
                        <p className="text-lg text-gray-600">Uploading file... Please wait.</p>
    </div>
)}


                {/* Flex container to display image and table side by side */}
                {guid && !errorMessage && !isLoading  && (
                    <div className="flex flex-col md:flex-row justify-center items-center items-start gap-6 w-full ">
                        <div className="md:w-1/2">
                            <img
                                src={pngUrl}
                                alt="Section PNG"
                                className="w-full h-auto shadow-md rounded-lg"
                            />
                        </div>

                        {jsonData && (
                            <div className="">
                                <h2 className="text-xl font-semibold mb-4">Data</h2>
                                <table className="table-auto bg-white shadow-md rounded-lg ">
                                    <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Property</th>
                                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderJsonData()}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}

            {/*    {guid && (
                    <div className="mt-6">
                        <h2 className="text-xl font-semibold mb-2">Download DXF</h2>
                        <a
                            href={dxfUrl}
                            download
                            className="inline-block px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
                        >
                            Download DXF File
                        </a>
                    </div>
                )}*/}
            </div>
        </div>
    );
};

export default SectionPage;
