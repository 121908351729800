import React, {useState, useEffect} from 'react';
import Viewer from "../components/TmpViewer";
import MaterialTakeOff from "../components/TmpMaterialTakeOff";
import {useParams} from 'react-router-dom';
import axios from "axios";
import {toast} from "react-toastify";

const TmpDemoPage = () => {
    const {name} = useParams();
    const [currentView, setCurrentView] = useState('viewer');
    const [loading, setLoading] = useState(true);
    const [file3d, setFile3d] = useState('');
    const [urlJson, setMergeJson] = useState('');
    const [find, setFind] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const baseUrl = process.env.REACT_APP_PROCESSOR_API;
                const endpoint = `/project/${name}`;
                const token = localStorage.getItem('authToken');
                console.log("###########")
                const response = await axios.get(`${baseUrl}${endpoint}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                console.log("~~~~~~~~~~~~~~~")
                console.log(response)

                if (response.status === 200) {
                    const files = response.data.files;
                    const file3d = files.find(file => file.original_file_name.endsWith('.3dm'))?.filename;
                    const mergeJson = files.find(file => file.original_file_name === 'merged_data.json')?.filename;

                    setFile3d(`${baseUrl}/project/file/${file3d}`);
                    setMergeJson(`${baseUrl}/project/file/${mergeJson}`);
                    setFind(true);
                } else {
                    console.log('Not authorized');
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.log('Not authorized');
                    toast.error('Not authorized', {
                        position: 'top-right',
                        autoClose: 5000,
                    });
                } else {
                    toast.error(error.message || 'An error occurred while fetching data.', {
                        position: 'top-right',
                        autoClose: 5000,
                    });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [name]);

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [name]);

    return (
        <div className="w-screen flex" style={{minHeight: 'calc(100vh - 85px)', background: "#2c2c48"}}>
            <div className="w-full bg-gray-500" style={{minHeight: 'calc(100vh - 85px)', background: "#2c2c48"}}>
                {find ? (
                    <>
                        <div className="bg-green-500 flex w-fit ml-auto mr-10 mt-2 z-10 ">
                            <div>
                                <button
                                    className={`text-white px-4 py-1 hover:bg-blue-600 transition-colors ${currentView === 'viewer' ? 'bg-blue-600' : 'bg-gray-500 '}`}
                                    onClick={() => handleViewChange('viewer')}
                                >
                                    3d Model
                                </button>
                            </div>
                            <div>
                                <button
                                    className={`text-white px-4 py-1 hover:bg-blue-600 transition-colors ${currentView === 'material' ? 'bg-blue-600' : 'bg-gray-500 '}`}
                                    onClick={() => handleViewChange('material')}
                                >
                                    Bill of Material
                                </button>
                            </div>
                        </div>
                        <div>
                            {loading ? (
                                <div className="text-white text-center mt-4">Loading...</div>
                            ) : (
                                <>
                                    {currentView === 'viewer' && <Viewer url3dm={file3d} urlJson={urlJson}/>}
                                    {currentView === 'material' && <MaterialTakeOff urlJson={urlJson}/>}
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="flex items-center justify-center min-h-screen ">
                        <div className="text-white text-lg text-center">
                            File not found
                        </div>
                    </div>

                )}
            </div>
        </div>

    );
};

export default TmpDemoPage;
