import React  from 'react';
import ServiceFlow from "../components/ServiceFlow";


const ServicePage = () => {

    return (
    <div >
        <ServiceFlow/>
    </div>
    );
};

export default ServicePage;
