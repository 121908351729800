// utils.js
import {useEffect} from "react";
import staticVariables from "../components/staticVariables";
import {EdgesGeometry, LineBasicMaterial, LineSegments} from "three";

export const defaultPrices = {
    "A-01": 10,
    "A-21": 10,
    "A-22": 10,
    "A-02": 10,
    "A-03": 10,
    "A-04": 10,
    "A-31": 10,
    "A-32": 10,
    "G-01": 100,
    "F-13": 1,
    "F-01": 2,
    "F-12": 0.5,
    "F-11": 0.5,
    "F-02": 1,
    "M-42": 10,
    "M-12": .5,
    "M-01": .5,
    "M-03": .5,
    "M-02": .5,
    "M-11": .5,
    "M-41": 20,
    "I-01": 20
    // Add more Part_Code and their default prices here as needed
};

export const fetchMaterials = async (url, filename) => {
    try {
        console.log(`${url}/merged_data.json`);
        let response;
        if (filename) {
            response = await fetch(`${url}/${filename}.json`);
        } else {

            response = await fetch(`${url}/merged_data.json`);
        }
        const data = await response.json();

        // Ensure each material has a default price and weight if not provided
        const materialsWithDefaults = data.map(material => {
            let total_price = 0;
            let value = 0; // Initialize value variable
            let price = material.price || defaultPrices[material.part_code] || 0;
            switch (material.unit) {
                case "sq.m":
                    value = material.area / 1000000; // Calculate value based on area
                    total_price = value * material.quantity * price;
                    break;
                case "mm":
                    value = material.length / 1000; // Calculate value based on length
                    total_price = value * material.quantity * price;
                    break;
                case "pieces":
                    value = NaN;
                    total_price = material.quantity * price; // Calculate total price directly for pieces
                    break;
                default:
                    total_price = 0;
            }

            return {
                ...material,
                value: value,
                price: price,
                weight: material.weight || 0,
                total_price: total_price,
            };
        });

        // Define the desired category order
        const categoryOrder = [
            "Glass",
            "Insulation",
            "Aluminium Extrusion",
            "Fastener",
            "Miscellaneous",
            // Add other categories here
        ];

        // Sort materials according to categoryOrder and then by part_code within each category
        const sortedMaterials = [...materialsWithDefaults].sort((a, b) => {
            // Get the index of categories in categoryOrder
            const indexA = categoryOrder.indexOf(a.category);
            const indexB = categoryOrder.indexOf(b.category);

            // If categories are not found in categoryOrder, place them at the end
            if (indexA === -1 && indexB === -1) {
                return 0; // Maintain current order
            } else if (indexA === -1) {
                return 1; // Place b after a
            } else if (indexB === -1) {
                return -1; // Place a before b
            }

            // Sort by categoryOrder first
            if (indexA !== indexB) {
                return indexA - indexB;
            }

            // Within the same category, sort by part_code
            return a.part_code.localeCompare(b.part_code);
        });

        return sortedMaterials;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Re-throw error for handling in the calling component
    }
};


export const fetchTmpMaterials = async (url) => {
    try {
        console.log(`${url}`);
        let response;
         response = await fetch(`${url}`);
        const data = await response.json();

        // Ensure each material has a default price and weight if not provided
        const materialsWithDefaults = data.map(material => {
            let total_price = 0;
            let value = 0; // Initialize value variable
            let price = material.price || defaultPrices[material.part_code] || 0;
            switch (material.unit) {
                case "sq.m":
                    value = material.area / 1000000; // Calculate value based on area
                    total_price = value * material.quantity * price;
                    break;
                case "mm":
                    value = material.length / 1000; // Calculate value based on length
                    total_price = value * material.quantity * price;
                    break;
                case "pieces":
                    value = NaN;
                    total_price = material.quantity * price; // Calculate total price directly for pieces
                    break;
                default:
                    total_price = 0;
            }

            return {
                ...material,
                value: value,
                price: price,
                weight: material.weight || 0,
                total_price: total_price,
            };
        });

        // Define the desired category order
        const categoryOrder = [
            "Glass",
            "Insulation",
            "Aluminium Extrusion",
            "Fastener",
            "Miscellaneous",
            // Add other categories here
        ];

        // Sort materials according to categoryOrder and then by part_code within each category
        const sortedMaterials = [...materialsWithDefaults].sort((a, b) => {
            // Get the index of categories in categoryOrder
            const indexA = categoryOrder.indexOf(a.category);
            const indexB = categoryOrder.indexOf(b.category);

            // If categories are not found in categoryOrder, place them at the end
            if (indexA === -1 && indexB === -1) {
                return 0; // Maintain current order
            } else if (indexA === -1) {
                return 1; // Place b after a
            } else if (indexB === -1) {
                return -1; // Place a before b
            }

            // Sort by categoryOrder first
            if (indexA !== indexB) {
                return indexA - indexB;
            }

            // Within the same category, sort by part_code
            return a.part_code.localeCompare(b.part_code);
        });

        return sortedMaterials;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Re-throw error for handling in the calling component
    }
};
export const useApplyLayoutEffects = (model) => {
    useEffect(() => {
        if (!model) return;

        model.traverse((child) => {
            if (child.isSprite) {
                child.scale.x = child.scale.x * 100;
                child.scale.y = child.scale.y * 100;
            }
            if (child.isMesh) {
                const userStrings = child.userData.attributes.userStrings;
                child.material = staticVariables.unhoveredghostedMaterial;

                if (userStrings && userStrings.length > 0) {
                    for (const attribute of userStrings) {
                        switch (attribute[0].toLowerCase()) {
                            case 'category':
                                switch (attribute[1].toLowerCase()) {
                                    case 'glass':
                                        child.material = staticVariables.glassMaterial;
                                        break;
                                    // Add more cases for other categories if needed
                                    // case 'fastener':
                                    //     child.material = staticVariables.fastenerMaterial;
                                    //     break;
                                    // case 'miscellaneous':
                                    //     child.material = staticVariables.miscellaneousMaterial;
                                    //     break;
                                    // case 'insulation':
                                    //     child.material = staticVariables.insulationMaterial;
                                    //     break;
                                    // default:
                                    //     child.material = staticVariables.unhoveredghostedMaterial;
                                }
                                break;
                            // Add more cases for other attributes if needed
                        }
                    }
                }

                // Create edges (optional, adjust as needed)
                const edges = new EdgesGeometry(child.geometry);
                const edgesMaterial = new LineBasicMaterial({color: 'white', linewidth: 2});
                const edgeLines = new LineSegments(edges, edgesMaterial);
                child.add(edgeLines);

                // Configure shadows if your scene has lighting that supports it
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });
    }, [model]);
};