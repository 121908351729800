import './App.css';
import Header from "./components/Header";
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import ServicePage from "./pages/ServicePage";
import FOFabPage from "./pages/FOFabPage";
import AboutPage from "./pages/AboutPage";
import ProjectPage from "./pages/ProjectPage";
import DemoPage from "./pages/DemoPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import RegisterPage from "./pages/RegisterPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import FileListPage from "./pages/FileListPage";
import PrivateRoute from "./service/PrivateRoute";
import TmpDemoPage from "./pages/TmpDemoPage";
import MiroPage from "./pages/MiroPage"; // Import PrivateRoute


function App() {
      const apiUrl = process.env.REACT_APP_PROCESSOR_API;
      console.log(apiUrl);
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/home" element={<HomePage/>}/>
                <Route path="/demo" element={<DemoPage/>}/> {/* Dynamic username */}
                <Route path="/service" element={<ServicePage/>}/>
                <Route path="/fofab" element={<FOFabPage/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/project" element={<ProjectPage/>}/>
                <Route path="/miro" element={<MiroPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route path="/profile" element={<PrivateRoute><ProfilePage/></PrivateRoute>}/> {/* Protected route */}
                <Route path="/verify/:token" element={<VerifyEmailPage/>}/>
                <Route path="/tmp" element={<PrivateRoute><FileListPage/></PrivateRoute>}/>
                <Route path="/tmp/:name" element={<PrivateRoute><TmpDemoPage/></PrivateRoute>}/>
            </Routes>
        </Router>
    );
}

export default App;
