import React, { useState } from 'react';
import ReactFlow, {Controls,Background,Position,} from 'reactflow';
import 'reactflow/dist/style.css';
import { useNavigate } from 'react-router-dom';


const edges = [
{ id: '1-2', source: '1', target: '2', type: 'smoothstep',animated: true, }
,{ id: '2-3', source: '2', target: '3', type: 'smoothstep',animated: true, }
,{ id: '3-5', source: '3', target: '5', type: 'smoothstep',animated: true, }
,{ id: '5-4', source: '5', target: '4', type: 'smoothstep',animated: true, }
,{ id: '4-1', source: '4', target: '1', type: 'smoothstep',animated: true,}

,{ id: '1-2', source: '6', target: '7', type: 'smoothstep' ,animated: true}
,{ id: '2-3', source: '7', target: '8', type: 'smoothstep' ,animated: true}
,{ id: '3-5', source: '6', target: '9', type: 'smoothstep' ,animated: true}
,{ id: '5-4', source: '9', target: '10', type: 'smoothstep',animated: true }

,{ id: '4-1', source: '6', target: '11', type: 'smoothstep',animated: true }
,{ id: '1-2', source: '11', target: '12', type: 'smoothstep',animated: true  }
,{ id: '2-3', source: '12', target: '13', type: 'smoothstep',animated: true  }
    ,{ id: '2-3', source: '15', target: '13', type: 'smoothstep',animated: true  }
    ,{ id: '2-3', source: '17', target: '13', type: 'smoothstep',animated: true  }

,{ id: '3-5', source: '6', target: '14', type: 'smoothstep',animated: true  }
,{ id: '5-4', source: '14', target: '15', type: 'smoothstep',animated: true  }

,{ id: '3-5', source: '6', target: '16', type: 'smoothstep',animated: true  }
,{ id: '5-4', source: '16', target: '17', type: 'smoothstep',animated: true  }


,{ id: '3-5', source: '18', target: '19', type: 'smoothstep',animated: true  }
,{ id: '5-4', source: '19', target: '20', type: 'smoothstep',animated: true  }
,{ id: '3-5', source: '19', target: '21', type: 'smoothstep',animated: true  }
,{ id: '3-5', source: '18', target: '22', type: 'smoothstep',animated: true  }
,{ id: '5-4', source: '22', target: '23', type: 'smoothstep',animated: true  }


];

const facadeOption = [
  {
    id: '1',
    data: { label: 'FacadeOption', content: '<div><h3>FacadeOption</h3><p>Here is some content about FacadeOption.</p><img src="https://via.placeholder.com/150" alt="Sample"/></div>' },
    href: 'home',
    position: { x: 200, y: 150 },
    style: { background: 'none', border: '2px solid red', color: 'white', borderRadius: '20px' },
      hoverStyle: {
  backgroundColor: '#B9FC6A', // Change background color on hover
},
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '2',
    data: { label: 'Facade Engineering' },
    position: { x: 400, y: 100 },
        style: { background: 'none', border: '2px solid blue', color: 'white', borderRadius: '20px' },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
     {
    id: '3',
    data: { label: 'Complex' },
    position: { x: 600, y: 100 },
        style: { background: 'none', border: '2px solid blue', color: 'white', borderRadius: '20px' },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
       {
    id: '4',
    data: { label: 'Automation' },
    position: { x: 400, y: 200 },
        style: { background: 'none', border: '2px solid green', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
       {
    id: '5',
    data: { label: 'made Simple' },
    position: { x: 600, y: 200 },
        style: { background: 'none', border: '2px solid green', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },


];

const softwareDevelopment = [
  {
    id: '6',
    data: { label: 'Software Development' },
    position: { x: 200, y: 450 },
    style: { background: 'none', border: '2px solid purple', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '7',
    data: { label: 'FOfab' },
     href: 'FOfab',
    position: { x: 400, y: 350 },
        style: { background: 'none', border: '2px solid purple', color: 'white', borderRadius: '20px'},
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
     {
    id: '8',
    data: { label: 'Rhino3d + Grasshopper Plugin' },
    position: { x: 600, y: 350 },
        style: { background: 'none', border: '2px solid purple', color: 'white', borderRadius: '20px', width:"250px"  },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
  {
    id: '9',
    data: { label: 'Online Facade Apps' },
    position: { x: 400, y: 400 },
        style: { background: 'none', border: '2px solid purple', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
       {
    id: '10',
    data: { label: 'available @ website' },
    position: { x: 600, y: 400 },
        style: { background: 'none', border: '2px solid purple', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
   sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },

    {
    id: '11',
    data: { label: 'FoHire' },
    position: { x: 400, y: 450 },
        style: { background: 'none', border: '2px solid grey', color: 'grey', borderRadius: '20px' },
             type: 'bidirectional',
  sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
       {
    id: '12',
    data: { label: 'Facade Head Hunting tools' },
    position: { x: 600, y: 450 },
        style: { background: 'none', border: '2px solid grey', color: 'grey', borderRadius: '20px', width:"200px" },
             type: 'bidirectional',
  sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
        {
    id: '13',
    data: { label: 'under development' },
    position: { x: 900, y: 450 },
        style: { background: 'none', border: '2px solid grey', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },

     {
    id: '14',
    data: { label: 'FoManage' },
    position: { x: 400, y: 500 },
        style: { background: 'none', border: '2px solid grey', color: 'grey', borderRadius: '20px' },
             type: 'bidirectional',
   sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
       {
    id: '15',
    data: { label: 'Facade Management tools' },
    position: { x: 600, y: 500 },
        style: { background: 'none', border: '2px solid grey', color: 'grey', borderRadius: '20px', width:"200px" },
             type: 'bidirectional',
  sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },

       {
    id: '16',
    data: { label: 'FoSupplyChain' },
    position: { x: 400, y: 550 },
        style: { background: 'none', border: '2px solid grey', color: 'grey', borderRadius: '20px' },
             type: 'bidirectional',
  sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
       {
    id: '17',
    data: { label: 'Facade Tender & Procurement tools' },
    position: { x: 600, y: 550 },
        style: { background: 'none', border: '2px solid grey', color: 'grey', borderRadius: '20px', width:"250px" },
             type: 'bidirectional',
      sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },

    {
    id: '18',
    data: { label: 'About' },
    position: { x: 200, y: 750 },
    style: { background: 'none', border: '2px solid #B9FC6A', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '19',
    data: { label: 'Founded' },
    position: { x: 400, y: 700 },
        style: { background: 'none', border: '2px solid #B9FC6A', color: 'white', borderRadius: '20px'},
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
     {
    id: '20',
    data: { label: 'Philippines' },
    position: { x: 600, y: 700 },
        style: { background: 'none', border: '2px solid #B9FC6A', color: 'white', borderRadius: '20px', },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
         {
    id: '21',
    data: { label: '2018' },
    position: { x: 600, y: 750 },
        style: { background: 'none', border: '2px solid #B9FC6A', color: 'white', borderRadius: '20px',width: "80px"  },

           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },

     {
    id: '22',
    data: { label: 'Contact Info' },
    position: { x: 400, y: 800 },
        style: { background: 'none', border: '2px solid #B9FC6A', color: 'white', borderRadius: '20px'},
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
     {
    id: '23',
    data: { label: 'info@facadeoption.com' },
    position: { x: 600, y: 800 },
        style: { background: 'none', border: '2px solid #B9FC6A', color: 'white', borderRadius: '20px',width: "180px" },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },


];

const nodes = [ ...softwareDevelopment];
function Flow() {
  const [modalVisible, setModalVisible] = useState(false);
  const [hoveredNode, setHoveredNode] = useState(null);
  const navigate = useNavigate();

  const onNodeMouseEnter = (event, node) => {
    setHoveredNode(node);
    setModalVisible(true);
  };

  const onNodeMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };

  const onNodeClick = (event, node) => {
      if (node.href){
    navigate(`/${node.href}`);}
  };


  const onFlowMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };




  return (
    <div className="w-screen relative" style={{ height: 'calc(100vh - 85px)' , background:"#2c2c48"}}>

      <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          onNodeClick={onNodeClick}
          onMouseLeave={onFlowMouseLeave}
          fitView={true}
      >
        {/*<Background />*/}
        <Controls />
      </ReactFlow>


    {modalVisible && hoveredNode && hoveredNode.data.content && (
        <div
          style={{
            position: 'absolute',
            top: 20,  // Offset for Y position
            right: 50, // Offset for X position
            padding: '10px',
            backgroundColor: 'white',
            border: '1px solid black',
            width: '300px',
            height: '500px',
            overflow: 'auto'
          }}
          dangerouslySetInnerHTML={{ __html: hoveredNode.data.content }}
        />
      )}
    </div>
  );
}

export default Flow;
