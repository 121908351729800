import React, { useState } from 'react';
import axios from 'axios';
import GlassOutputTable from '../components/GlassOutputTable'; // Import the new output component

const Glass4Form = () => {
  const [formData, setFormData] = useState({
    basic_wl: 2,
    coefficient: 1,
    glass_b: 2000,
    glass_a: 2000,
    glass_1_t: 8,
    glass_2_t: 8,
    glass_1_type: "Annealed",
    glass_2_type: "Annealed",
    glass_build: "Laminated",
  });

  const [result, setResult] = useState(null); // To hold the output data

  // Glass type options
  const glassTypes = ["Annealed", "Heat-Strengthened", "Tempered"];

  // Glass thickness options
  const glassThicknesses = [3, 4, 5, 6, 8, 10, 12, 15, 19, 22, 25];
    const [isLoading, setIsLoading] = useState(false);
  // Glass build options
  const glassBuildOptions = ["Monolithic", "Laminated", "Insulating Glass Unit"];
    const baseUrl = process.env.REACT_APP_PROCESSOR_API;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setResult(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      const response = await axios.post(`${baseUrl}/calculator/glass-4-side`, formData);
      setResult(response.data); // Store the response data in the result state
    } catch (error) {
      console.error('Error submitting form:', error);
    }
      setIsLoading(false)
  };



  return (
    <div className="max-w-7xl p-8 ">
      <h1 className="text-2xl font-semibold  mb-6">4-Sided Glass Calculator</h1>
      <div className="grid grid-cols-2 gap-8">
        {/* Form Section */}
        <div className='max-w-xl '>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-700">Wind Load</label>
                <input
                  type="number"
                  name="basic_wl"
                  value={formData.basic_wl}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="hidden">
                <label className="block text-gray-700">Coefficient</label>
                <input
                  type="number"
                  name="coefficient"
                  value={formData.coefficient}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-700">Glass Build</label>
                <select
                  name="glass_build"
                  value={formData.glass_build}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {glassBuildOptions.map((build) => (
                    <option key={build} value={build}>
                      {build}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-700">Width</label>
                <input
                  type="number"
                  name="glass_a"
                  value={formData.glass_a}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

                <div>
                  <label className="block text-gray-700">Height</label>
                  <input
                    type="number"
                    name="glass_b"
                    value={formData.glass_b}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-700">Lite 1</label>
                <select
                  name="glass_1_type"
                  value={formData.glass_1_type}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {glassTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              {formData.glass_build !== "Monolithic" && (
                <div>
                  <label className="block text-gray-700">Lite 2</label>
                  <select
                    name="glass_2_type"
                    value={formData.glass_2_type}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    {glassTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-700">Lite 1 </label>
                <select
                  name="glass_1_t"
                  value={formData.glass_1_t}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {glassThicknesses.map((thickness) => (
                    <option key={thickness} value={thickness}>
                      {thickness} mm
                    </option>
                  ))}
                </select>
              </div>

              {formData.glass_build !== "Monolithic" && (
                <div>
                  <label className="block text-gray-700">Lite 2 </label>
                  <select
                    name="glass_2_t"
                    value={formData.glass_2_t}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    {glassThicknesses.map((thickness) => (
                      <option key={thickness} value={thickness}>
                        {thickness} mm
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <div className="right mt-6">
              <button
                type="submit"
                className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Calculate
              </button>
            </div>
          </form>
        </div>

      {isLoading ? (
  <div className="mb-4 flex flex-col items-center justify-center space-y-2">
    {/* Spinner */}
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>

    {/* Uploading text */}
    <p className="text-lg text-gray-600">Computing... Please wait.</p>
  </div>
) : (
  <div className="border-l pl-8">
    <GlassOutputTable result={result} glass_build={formData.glass_build} /> {/* Display output when loading is complete */}
  </div>
)}

      </div>
    </div>
  );
};

export default Glass4Form;
