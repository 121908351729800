import React, { useState } from 'react';
import ReactFlow, { Controls, Background, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import { useNavigate } from 'react-router-dom';

const edges = [
  { id: '1-2', source: '1', target: '2', type: 'bezier', animated: true },
  { id: '2-3', source: '1', target: '3', type: 'bezier', animated: true },
  { id: '3-5', source: '1', target: '4', type: 'bezier', animated: true },
  { id: '5-4', source: '5', target: '1', type: 'bezier', animated: true },
  { id: '4-1', source: '6', target: '1', type: 'bezier', animated: true },
  { id: '1-2', source: '1', target: '7', type: 'bezier', animated: true },
  { id: '2-3', source: '1', target: '8', type: 'bezier', animated: true },
];

const facadeOption = [
  {
    id: '1',
    data: { label: 'Philippines', content: '<div><h3>FacadeOption</h3><p>Here is some content about FacadeOption.</p><img src="/s1.png" alt="Sample"/></div>' },
    href: 'home',
    position: { x: 650, y: 250 },
    type: 'bidirectional',
    sourcePosition: Position.Top,
    targetPosition: Position.Bottom,
    style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
  },
  {
    id: '2',
    data: { label: 'Vietnam' },
    position: { x: 550, y: 220 },
    style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  // {
  //   id: '3',
  //   data: { label: 'Thailand',content: '<div class="modal-content">\n' +
  //         '    <div class="container-about">\n' +
  //         '      <div class="image-container">\n' +
  //         '        <img src="/t1.png" alt="Sample" style="height: 350px;" />\n' +
  //         '        <div class="text-container">\n' +
  //         '          <h1 class="text-xl text-center">The TARA</h1>\n' +
  //         '        </div>\n' +
  //         '      </div>\n' +
  //         '    </div>\n' +
  //         '  </div>' },
  //   position: { x: 550, y: 250 },
  //   style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
  //   type: 'bidirectional',
  //   sourcePosition: Position.Right,
  //   targetPosition: Position.Right,
  // },
  {
    id: '4',
    data: { label: 'Malaysia' },
    position: { x: 500, y: 280 },
    style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '5',
    data: { label: 'Namibia',content:'<div class="modal-content"><div class="container-about"><div class="image-container"><img src="/s3.png" alt="Sample" style="height: 350px;" /><div class="text-container"><h1 class="text-xl text-center">Swapo HQ</h1></div></div></div></div>' },
    position: { x: 310, y: 330 },
    style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '6',
    data: { label: 'South Africa', content: '<div class="modal-content"><div class="container-about"><div class="image-container"><img src="/s1.png" alt="Sample" style="height: 350px;" /><div class="text-container"><h1 class="text-xl text-center">35 Lower Long </h1></div></div><div class="image-container"><img src="/s2.png" alt="Sample" style="height: 350px;" /><div class="text-container"><h1 class="text-xl text-center">The Rockefeller</h1></div></div></div></div>' },
    position: { x: 400, y: 410 },
    style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '7',
    data: { label: 'New York',content: '<div class="modal-content">\n' +
          '    <div class="container-about">\n' +
          '      <div class="image-container">\n' +
          '        <img src="/n1.png" alt="Sample" style="height: 250px;" />\n' +
          '        <div class="text-container">\n' +
          '          <h1 class="text-xl text-center">Brooklyn Community District 2 Garage</h1>\n' +
          '        </div>\n' +
          '      </div>\n' +
          '      <div class="image-container">\n' +
          '        <img src="/n2.png" alt="Sample" style="height: 250px;" />\n' +
          '        <div class="text-container">\n' +
          '        <h1 class="text-xl text-center">153-24 90th Road</h1>\n' +
          '        </div>\n' +
          '      </div>\n' +
          '\t    <div class="image-container">\n' +
          '        <img src="/n3.png" alt="Sample" style="height: 250px;" />\n' +
          '        <div class="text-container">\n' +
          '        <h1 class="text-xl text-center">Stony Brooks</h1>\n' +
          '        </div>\n' +
          '      </div>\n' +
          '    </div>\n' +
          '  </div>' },
    position: { x: 190, y: 220 },
    style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
    type: 'bezier',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '8',
    data: { label: 'California' },
    position: { x: 50, y: 220 },
    style: { background: 'rgba(85, 0, 255, 0.5)', color: '#F8C825', border: '1px solid #3776FD', borderRadius: '20px', padding: "0px", width: "80px" },
    type: 'bezier',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
];

const map = [
  {
    id: '99',
    data: { label: '' },
    position: { x: 0, y: 0 },
    style: {
      background: 'url(map.png) no-repeat center center fixed',
      backgroundSize: 'cover',
      border: '2px none purple',
      color: 'white',
      width: '800px',
      height: '500px',
      zIndex: '-1',
      borderRadius: '20px'
    },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
];

const nodes = [...map, ...facadeOption];

function Flow() {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const navigate = useNavigate();

  const htmlContent = `
  <div class="modal-content">
    <div class="container-about">
      <div class="image-container">
        <img src="/s1.png" alt="Sample" style="height: 350px;" />
        <div class="text-container">
          <h1 class="text-xl text-center">35 Lower Long </h1>
        </div>
      </div>
      <div class="image-container">
        <img src="/s2.png" alt="Sample" style="height: 350px;" />
        <div class="text-container">
        <h1 class="text-xl text-center">The Rockefeller</h1>
        </div>
      </div>
    </div>
  </div>
`;
  const onNodeClick = (event, node) => {
    if (node.href) {
      navigate(`/${node.href}`);
    } else if (node.data.content) {
      setModalContent(node.data.content);
      setModalVisible(true);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalContent(null);
  };

  return (
    <div className="w-screen relative" style={{ height: 'calc(100vh - 85px)', background: "#2c2c48" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeClick={onNodeClick}
        fitView
      >
        <Controls />
      </ReactFlow>

      {modalVisible && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 5)',
          padding: '20px',
          boxShadow: '0px 0px 10px rgba(0,0,0,0.25)',
          zIndex: 1000

        }}>

          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </div>
      )}

      {modalVisible && (
        <div
          onClick={closeModal}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 999,
          }}
        />
      )}
    </div>
  );
}

export default Flow;
