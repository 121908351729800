import React from 'react';
import {Link} from "react-router-dom";

const AppPage = () => {
    return (
        <div className="w-screen h-screen flex pt-20 justify-center bg-gray-800">
            {/* 2D Button */}
            <div className="flex flex-col space-y-6 items-center">
                <div className="flex space-x-6">
                    <Link to="/demo"
                        className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg shadow-md border border-gray-300 hover:bg-gray-300 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-400">
                        Demo (3D)
                    </Link>
                    <Link  to="https://editor.facadeoption.com/docs/playground/"  target="_blank"
                        className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg shadow-md border border-gray-300 hover:bg-gray-300 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-400">
                        Extrusion Designer (2D)
                    </Link>
                       <Link  to="/section"
                        className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg shadow-md border border-gray-300 hover:bg-gray-300 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-400">
                        Section Properties
                    </Link>
                        <Link  to="/calculator/glass"
                        className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg shadow-md border border-gray-300 hover:bg-gray-300 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-400">
                        4-Sided Glass Calculator
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AppPage;
