import React, {useRef} from 'react';
import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const MyPieChart = ({materials}) => {
    const chartRef = useRef(null); // Declare chartRef using useRef hook

    // Function to merge materials by category and calculate total quantity and price
    const mergeMaterialsByCategory = (materials) => {
        const mergedData = {};

        materials.forEach(material => {
            const category = material.category;
            if (!mergedData[category]) {
                mergedData[category] = {
                    category: category,
                    quantity: 0,
                    totalPrice: 0
                };
            }
            mergedData[category].totalPrice += material.total_price;
        });
        console.log(mergedData);
        return Object.values(mergedData);
    };

    // Generate merged data for pie chart
    const mergedMaterials = mergeMaterialsByCategory(materials);

    // Generate background colors based on fixed array
    const fixedColors = [
        'rgba(255, 99, 132, 1)',   // Red
        'rgba(54, 162, 235, 1)',   // Blue
        'rgba(255, 206, 86, 1)',   // Yellow
        'rgba(75, 192, 192, 1)',   // Teal
        'rgba(153, 102, 255, 1)',  // Purple
    ];

    const backgroundColor = mergedMaterials.map((material, index) => {
        const colorIndex = index % fixedColors.length; // Loop through fixedColors array
        return fixedColors[colorIndex];
    });

    const data = {
        labels: mergedMaterials.map(material => material.category),
        datasets: [
            {
                data: mergedMaterials.map(material => material.totalPrice), // Total price per category
                backgroundColor: backgroundColor,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    boxWidth: 20,
                    padding: 15,
                    color:'white'
                },
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                color: 'white',
                formatter: (value, context) => {
                    const dataset = context.chart.data.datasets[0];
                    const total = dataset.data.reduce((a, b) => a + b, 0);
                    const percentage = ((value / total) * 100).toFixed(0) + '%';
                    return percentage;
                },
                font: {
                    weight: 'bold',
                },
            },
        },
        cutout: '70%',
        elements: {
            arc: {
                borderWidth: 0,
                hoverBorderWidth: 0,
                borderRadius: 0,
                borderJoinStyle: 'round',
            },
        },
    };

    // Component to display centered text in the pie chart
    const CenteredText = ({total}) => (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '36%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                pointerEvents: 'none',
            }}
        >
            <div className="text-white font-bold text-xl">${total}</div>
            <div className="text-white">Total Cost</div>
        </div>
    );

    // Function to calculate total cost from materials array
    const getTotalCost = () => {
        return materials.reduce((total, material) => {
            return total +  material.total_price;
        }, 0).toFixed(0);
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{position: 'relative', width: '500px', height: '500px'}}>
                <Pie data={data} options={options} ref={chartRef}/>
                <CenteredText total={getTotalCost()}/>
            </div>
        </div>
    );
};

export default MyPieChart;
