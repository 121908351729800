import React, {useState, useEffect, useLayoutEffect} from 'react';
import {Canvas, useLoader} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';
import {Rhino3dmLoader} from 'three/examples/jsm/loaders/3DMLoader';
import {Euler, Box3, Vector3, EdgesGeometry, LineBasicMaterial, LineSegments} from 'three';
import staticVariables from './staticVariables';
import {fetchMaterials, fetchTmpMaterials, useApplyLayoutEffects} from '../rhino/utils'; // Import the utility function

function Rhino3dmLogo({url, color = 'black', onModelLoaded, ...props}) {
    const model = useLoader(Rhino3dmLoader, url, (loader) =>
        loader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@0.15.0-beta/')
    );

    useEffect(() => {
        if (onModelLoaded) {
            const box = new Box3().setFromObject(model);
            const size = box.getSize(new Vector3());
            const center = box.getCenter(new Vector3());
            onModelLoaded({size, center});
        }
    }, [model, onModelLoaded]);

    useApplyLayoutEffects(model);

    return <primitive object={model} {...props} />;
}

const Viewer = ({url3dm,urlJson}) => {
    const [modelSize, setModelSize] = useState(null);
    const [modelCenter, setModelCenter] = useState(null);
    const [cameraPosition, setCameraPosition] = useState([0, 1500, 2500]); // Initial camera position
    const rhinoToThreeRotation = new Euler(-Math.PI / 2, 0, 0); // Rotate 180 degrees around the Y axis

    const [materials, setMaterials] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // State to track loading status


    useEffect(() => {
        const loadMaterials = async () => {
            try {
                const fetchedMaterials = await fetchTmpMaterials(urlJson); // Pass folder here
                setMaterials(fetchedMaterials);
            } catch (error) {
                console.error('Error fetching materials:', error);
            } finally {
                setIsLoading(false); // Update loading state when materials are fetched
            }
        };

        loadMaterials();
    }, [urlJson]); // Add folder as a dependency


    const handleModelLoaded = ({size, center}) => {
        setModelSize(size);
        setModelCenter(center);

        // Calculate new camera position based on model size (optional, adjust as needed)
        const maxModelSize = Math.max(size.x, size.y, size.z);
        const fov = 75; // Adjust the field of view as needed
        const cameraZ = maxModelSize / Math.tan((fov * Math.PI) / 360) * 2;
        const newCameraPosition = [center.x, center.y, cameraZ];
        setCameraPosition(newCameraPosition);
    };

     const aspect = window.innerWidth / window.innerHeight;
    const zoom = 1; // Adjust the zoom level as needed


    return (

        <div className="flex">
            <div className="w-3/4" style={{minHeight: 'calc(100vh - 100px)'}}>
                <Canvas
                    camera={{
                        position: cameraPosition,
                        up: [0, 1, 0],
                        zoom: zoom,
                        left: -aspect * zoom,
                        right: aspect * zoom,
                        top: zoom,
                        bottom: -zoom,
                        near: 0.1,
                        far: 2000000,
                    }}
                >
                    <ambientLight intensity={0.5}/>
                    {/* Example directional lights (adjust as needed) */}
                    <directionalLight position={[-10, 10, -10]} intensity={0.5}/>
                    <directionalLight position={[10, 10, -10]} intensity={0.5}/>
                    <directionalLight position={[-10, -10, -10]} intensity={0.5}/>
                    <directionalLight position={[10, -10, -10]} intensity={0.5}/>
                    <directionalLight position={[0, 10, 0]} intensity={0.5}/>
                    <directionalLight position={[0, -10, 0]} intensity={0.5}/>
                    <directionalLight position={[0, 0, 10]} intensity={0.5}/>
                    <directionalLight position={[0, 0, -10]} intensity={0.5}/>

                    {/* Render Rhino3dmLogo only when not loading */}
                    {!isLoading && (
                        <Rhino3dmLogo
                            url={`${url3dm}`}
                            color="9CAFAA"
                            rotation={[rhinoToThreeRotation.x, rhinoToThreeRotation.y, rhinoToThreeRotation.z]}
                            onModelLoaded={handleModelLoaded}
                        />
                    )}

                    <OrbitControls target={[modelCenter?.x || 0, modelCenter?.y || 0, modelCenter?.z || 0]}
                                   enableDamping dampingFactor={0.1} rotateSpeed={2} zoomSpeed={2}/>
                </Canvas>
            </div>
            <div className="overflow-y-auto mr-10 h-full" style={{minHeight: 'calc(100vh - 100px)', overflowY: "auto"}}>
                {/* Render loading indicator or material list based on isLoading state */}
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <table className="table-auto bg-white w-full">
                        <thead className="bg-primary-color text-white">
                        <tr>
                            <th className="px-4 text-center">Code</th>
                            <th className="px-4 text-left">Name</th>
                            <th className="px-4 text-center">Qty</th>
                            <th className="px-2 text-right">Value</th>
                            <th className="px-2 text-center">Unit</th>
                        </tr>
                        </thead>
                        <tbody>
                        {materials.map(material => (
                            <tr key={material.foid} className="border-b">
                                <td className="px-4 text-center whitespace-nowrap">{material.part_code}</td>
                                <td className="px-4">{material.description}</td>
                                <td className="px-4 text-center">{material.quantity}</td>
                                <td className="px-2 w-20 text-right">
                                    {isNaN(material.value) || material.value === 0 || material.value === 0.000 ? '' : material.value.toFixed(3)}
                                </td>
                                <td className="px-2 text-center ">{material.unit === "mm" ? "m" : material.unit}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default Viewer;