import * as THREE from "three";
import {EdgesGeometry, LineBasicMaterial, LineSegments, MeshPhongMaterial} from "three";

const staticVariables = {

    hoveredghostedMaterial: new MeshPhongMaterial({
        color: "hotpink",
        opacity: 0.50,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),

    inProgressghostedMaterial: new MeshPhongMaterial({
        color: "green",
        opacity: 0.50,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),
    errorGhostedMaterial: new MeshPhongMaterial({
        color: "red",
        opacity: 0.50,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),
    unhoveredghostedMaterial: new MeshPhongMaterial({
        color: "grey",
        opacity: 0.50,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),
    glassMaterial: new THREE.MeshPhysicalMaterial({
     color: "#03fce3",
        opacity: 0.50,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),
    aluminumMaterial: new THREE.MeshStandardMaterial({
      color: "grey",
        opacity: 0.50,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),

    miscellaneousMaterial: new THREE.MeshStandardMaterial({
     color: "grey",
        opacity: 0.90,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),
    fasternerMaterial: new THREE.MeshStandardMaterial({
       color: "black",
        opacity: 0.70,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),
    insulationMaterial: new THREE.MeshStandardMaterial({
         color: "grey",
        opacity: 0.50,
        transparent: true,
        depthWrite: false,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide
    }),
    inProgressUUIDs: ["2", "3", "4"],
    ErrorUUIDs: ["", "6"],
    dummy: [
        {
            "foid": "1",
            "taskDetails": [{
                "id": 1,
                "assignedTo": "John Doe",
                "targetDate": "2024-04-12",
                "taskNotes": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget semper nisi.",
                "tag1": "B",
                "tag2": "B1",
                "taskDesignation": "Design",
                "status": "todo",
                "comment": [
                    {"name": "John", "comment": "Vestibulum ac nisl ac velit"},
                    {"name": "Jonalyn", "comment": "Vestibulum ac nisl ac velit"},
                    {"name": "Alice", "comment": "Sed aliquet urna ac mauris convallis, eget lobortis ex consequat."}
                ]
            },
                {
                    "id": 2,
                    "assignedTo": "John Doe",
                    "targetDate": "2024-04-12",
                    "taskNotes": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget semper nisi.",
                    "tag1": "B",
                    "tag2": "B1",
                    "taskDesignation": "Procurement",
                    "status": "Validation",
                    "comment": [
                        {"name": "John", "comment": "Vestibulum ac nisl ac velit"},
                        {"name": "Jonalyn", "comment": "Vestibulum ac nisl ac velit"},
                        {
                            "name": "Alice",
                            "comment": "Sed aliquet urna ac mauris convallis, eget lobortis ex consequat."
                        }
                    ]
                }
            ],

        },
        {
            "foid": "2",
            "taskDetails": [{
                "id": 3,
                "assignedTo": "Alice Smith",
                "targetDate": "2024-04-15",
                "taskNotes": "Sed aliquet urna ac mauris convallis, eget lobortis ex consequat.",
                "tag1": "A",
                "tag2": "A2",
                "taskDesignation": "Design",
                "status": "inProgress",
                "comment": [
                    {"name": "John", "comment": "Duis id eros id leo malesuada pharetra."},
                    {
                        "name": "Jonalyn",
                        "comment": "Ut interdum velit sit amet lorem tincidunt, id bibendum nunc volutpat."
                    }
                ]
            }],

        },
        {
            "foid": "3",
            "taskDetails": [{
                "id": 4,
                "assignedTo": "Bob Johnson",
                "startDate": "2024-03-18",
                "targetDate": "2024-04-18",
                "taskNotes": "Duis id eros id leo malesuada pharetra. Ut interdum velit sit amet lorem tincidunt, id bibendum nunc volutpat.",
                "tag1": "A",
                "tag2": "A3",
                "taskDesignation": "Design",
                "status": "completed",
                "comment": [
                    {"name": "Alice", "comment": "Integer sed mauris eget orci varius fermentum."},
                    {"name": "Michael", "comment": "In ut nulla nec risus congue pretium."}
                ]
            }],

        },
        {
            "foid": "4",
            "taskDetails": [{
                "id": 5,
                "assignedTo": "Emily Brown",
                "targetDate": "2024-04-20",
                "taskNotes": "Integer sed mauris eget orci varius fermentum. In ut nulla nec risus congue pretium.",
                "tag1": "A",
                "tag2": "A4",
                "taskDesignation": "Design",
                "status": "todo",
                "comment": [
                    {"name": "Emily", "comment": "Vestibulum ac nisl ac velit ultrices rutrum nec a enim."}
                ]

            }],

        },
        {
            "foid": "5",
            "taskDetails": [{
                "id": 6,
                "assignedTo": "Michael Lee",
                "targetDate": "2024-04-25",
                "taskNotes": "Vestibulum ac nisl ac velit ultrices rutrum nec a enim. Vivamus gravida nisi sit amet ultrices faucibus.",
                "taskDesignation": "Design",
                "status": "inProgress"
            },
                {
                    "id": 7,
                    "assignedTo": "Michael Lee",
                    "startDate": "2024-04-25",
                    "targetDate": "2024-04-25",
                    "taskNotes": "Vestibulum ac nisl ac velit ultrices rutrum nec a enim. Vivamus gravida nisi sit amet ultrices faucibus.",
                    "taskDesignation": "Design",
                    "status": "inProgress",
                    "comment": [
                        {"name": "Michael", "comment": "Vivamus gravida nisi sit amet ultrices faucibus."},
                        {"name": "John", "comment": "Nullam eget semper nisi."}
                    ]
                }],

        }
    ]

};


export default staticVariables;
