import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../service/httpService';

const SignupPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        confirmPassword: '',
    });
    const [notification, setNotification] = useState(null);
    const [errors, setErrors] = useState({});
    const [formVisible, setFormVisible] = useState(true); // State to control form visibility
    const [loading, setLoading] = useState(false); // State to control loading state
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.password) newErrors.password = 'Password is required';
        if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true); // Set loading to true when starting the request

        try {
            const response = await post('/sso/account/signup', formData);

            if (response.status === 200) {
                setNotification({
                    type: 'success',
                    message: 'Signup successful! Please check your email for verification.',
                });

                // Hide form and show notification only on success
                setFormVisible(false);

                // Optionally clear form data
                setFormData({
                    email: '',
                    name: '',
                    password: '',
                    confirmPassword: '',
                });
            } else {
                setNotification({
                    type: 'error',
                    message: 'Signup failed. Please try again.',
                });
            }
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message || 'An unexpected error occurred';
            setNotification({
                type: 'error',
                message: errorMessage,
            });
        } finally {
            setLoading(false); // Set loading to false when the request is complete
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white shadow-lg -mt-52 rounded-lg">
                {formVisible && (
                    <h1 className="text-2xl font-bold text-center text-gray-900 mb-6">Signup</h1>
                )}
                {/* Display notification if available */}
                {notification && (
                    <div className={`p-4 mb-4 text-white rounded-md ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'}`}>
                        {notification.message}
                    </div>
                )}

                {/* Display form if it is visible */}
                {formVisible && (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
                        </div>
                        <div className="mb-6">
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                            {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            disabled={loading} // Disable button when loading
                        >
                            {loading ? 'Signing up...' : 'Signup'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default SignupPage;
