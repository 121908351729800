import React  from 'react';
import AboutFlow from "../components/AboutFlow";


const ServicePage = () => {

    return (
    <div >
        <AboutFlow/>
    </div>
    );
};

export default ServicePage;
