import React  from 'react';
import FOFabFlow from "../components/FOFabFlow";


const ServicePage = () => {

    return (
    <div >
        <FOFabFlow/>
    </div>
    );
};

export default ServicePage;
