import React, { useState } from 'react';
import ReactFlow, {Controls,Background,Position,} from 'reactflow';
import 'reactflow/dist/style.css';
import { useNavigate } from 'react-router-dom';


const edges = [
{ id: '1-2', source: '1', target: '2', type: 'smoothstep',animated: true, }
,{ id: '2-3', source: '2', target: '3', type: 'smoothstep',animated: true, }
,{ id: '3-5', source: '3', target: '5', type: 'smoothstep',animated: true, }
,{ id: '5-4', source: '5', target: '4', type: 'smoothstep',animated: true, }
,{ id: '4-1', source: '4', target: '1', type: 'smoothstep',animated: true,}

,{ id: '1-2', source: '1', target: '6', type: 'smoothstep' ,animated: true}
,{ id: '2-3', source: '6', target: '7', type: 'smoothstep' ,animated: true}


];

const facadeOption = [
  {
    id: '1',
    data: { label: 'FacadeOption', content: ' <div class="rounded">\n' +
            '        <h1 class="display-4 text-center fade-in">Facade Option</h1>\n' +
            '        <p class="lead text-center fade-in">A facade engineering company providing services to Contractors, Consultants, Architects, and Developers.</p>\n' +
            '        <hr class="my-4">\n' +
            '        <p class="text-center fade-in">Based in Manila, Philippines. Founded in 2018.</p>\n' +
            '        <p class="text-center fade-in">Focuses on design automation workflows using Rhino with custom scripting for work efficiency.</p>\n' +
            '        </div>\n' +
            '        </div>' },
    href: 'home',
    position: { x: 200, y: 150 },
    style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
  {
    id: '2',
    data: { label: 'Facade Engineering' },
    position: { x: 400, y: 100 },
        style: { background: 'none', border: '2px solid #3776FD', color: 'white', borderRadius: '20px' },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
     {
    id: '3',
    data: { label: 'Complex' },
    position: { x: 600, y: 100 },
        style: { background: 'none', border: '2px solid blue', color: 'white', borderRadius: '20px' },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
       {
    id: '4',
    data: { label: 'Automation' },
    position: { x: 400, y: 150 },
        style: { background: 'none', border: '2px solid #C0ED2B', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
       {
    id: '5',
    data: { label: 'made Simple' },
    position: { x: 600, y: 150 },
        style: { background: 'none', border: '2px solid #C0ED2B', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },

         {
    id: '6',
    data: { label: 'Design' },
    position: { x: 400, y: 200 },
        style: { background: 'none', border: '2px solid #7430ED', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
       {
    id: '7',
    data: { label: 'to Build' },
    position: { x: 600, y: 200 },
        style: { background: 'none', border: '2px solid #7430ED', color: 'white', borderRadius: '20px' },
             type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },


];


const nodes = [...facadeOption];
function Flow() {
  const [modalVisible, setModalVisible] = useState(false);
  const [hoveredNode, setHoveredNode] = useState(null);
  const navigate = useNavigate();

  const onNodeMouseEnter = (event, node) => {
    setHoveredNode(node);
    setModalVisible(true);
  };

  const onNodeMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };

  const onNodeClick = (event, node) => {
      if (node.href){
    navigate(`/${node.href}`);}
  };


  const onFlowMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };




  return (
    <div className="w-screen relative" style={{ height: 'calc(100vh - 85px)' , background:"#2c2c48"}}>

      <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          onNodeClick={onNodeClick}
          onMouseLeave={onFlowMouseLeave}
          fitView={true}
      >
        {/*<Background />*/}
        <Controls />
      </ReactFlow>


    {modalVisible && hoveredNode && hoveredNode.data.content && (
        <div
          style={{
            position: 'absolute',
            top: 20,  // Offset for Y position
            right: 50, // Offset for X position
            padding: '10px',
            backgroundColor: 'white',
            border: '1px solid black',
            width: '300px',
            height: '500px',
            overflow: 'auto'
          }}
          dangerouslySetInnerHTML={{ __html: hoveredNode.data.content }}
        />
      )}
    </div>
  );
}

export default Flow;
