import React  from 'react';
import ProjectFlow from "../components/ProjectFlow";


const ProjectPage = () => {

    return (
<div className="max-w-7xl" style={{ backgroundImage: `url('map.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
    <ProjectFlow></ProjectFlow>
</div>


    );
};

export default ProjectPage;
