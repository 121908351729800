import React from 'react';

const MiroPage = () => {

    return (
        <div className="relative w-full" style={{ paddingBottom: '46.35%' }}>
            <iframe id="miro-iframe"
                    src="https://miro.com/app/live-embed/uXjVKtmsZBg=/?moveToViewport=-1549,-3562,23998,12255&embedId=633537480696"
                    className="absolute inset-0 w-full h-full"
                    frameBorder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write"
                    allowFullScreen>
            </iframe>
        </div>
    );
};

export default MiroPage;
