import React, {useState, useEffect} from 'react';
import Viewer from "../components/Viewer";
import MaterialTakeOff from "../components/MaterialTakeOff";
import {useLocation} from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const DemoPage = () => {
    const [currentView, setCurrentView] = useState('viewer');
    const [numPanels, setNumPanels] = useState(1); // default to 1 panel
    const [height, setHeight] = useState("2500"); // default height value
    const [width, setWidth] = useState("1500"); // default width value
    const [folder, setFolder] = useState(`${height}x${width}`); // initialize folder based on default height and width
    const [loading, setLoading] = useState(true); // Loading state

    const query = useQuery();
    const name = query.get('name');

    let url;
    let filename;
    if (name) {
        url = `https://app.facadeoption.com/file/demo`;
        filename = name
    } else {
          url = `/webfile/${folder}`;
          filename = folder
    }


    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const handleCompute = () => {
        const newFolder = `${height}x${width}`;
        setFolder(newFolder);
    };

    useEffect(() => {
        setLoading(true); // Set loading to true when folder changes
        // Simulate loading delay
        setTimeout(() => {
            setLoading(false);
        }, 1000); // Adjust delay time as needed
    }, [folder]);

    return (
        <div className="w-screen flex" style={{minHeight: 'calc(100vh - 85px)', background: "#2c2c48"}}>
            <div className="w-1/8 border-r-2">
                <div className="flex flex-col justify-start p-4">
                    <div className="text-white text-center justify-center w-28 h-fit px-2 rounded mb-4 bg-green-500">
                        <img src="/typical.png" alt="Sample" className="h-20 m-auto"/>
                        TYPICAL
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="height">
                            Height
                        </label>
                        <select
                            className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="height"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                        >
                            <option value="2500">2.5</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="width">
                            Width
                        </label>
                        <select
                            className="text-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="width"
                            value={width}
                            onChange={(e) => setWidth(e.target.value)}
                        >
                            <option value="1500">1.5</option>
                            <option value="2000">2</option>
                            <option value="2500">2.5</option>
                        </select>
                    </div>
                    <div className="flex justify-end">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                            onClick={handleCompute}
                        >
                            Compute
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-full bg-gray-500" style={{minHeight: 'calc(100vh - 85px)', background: "#2c2c48"}}>
                <div className="bg-green-500 flex w-fit ml-auto mr-10 mt-2 z-10 ">
                    <div>
                        <button
                            className={`text-white px-4 py-1 hover:bg-blue-600 transition-colors ${currentView === 'viewer' ? 'bg-blue-600' : 'bg-gray-500 '}`}
                            onClick={() => handleViewChange('viewer')}
                        >
                            3d Model
                        </button>
                    </div>
                    <div>
                        <button
                            className={`text-white px-4 py-1 hover:bg-blue-600 transition-colors ${currentView === 'material' ? 'bg-blue-600' : 'bg-gray-500 '}`}
                            onClick={() => handleViewChange('material')}
                        >
                            Bill of Material
                        </button>
                    </div>
                </div>
                <div>
                    {loading ? (
                        <div className="text-white text-center mt-4">Loading...</div>
                    ) : (
                        <>
                            {currentView === 'viewer' && <Viewer url={url} filename={filename}/>}
                            {currentView === 'material' && <MaterialTakeOff url={url} filename={filename}/>}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DemoPage;
