import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown visibility
    const location = useLocation();
    const userName = localStorage.getItem('name'); // Get the user name from localStorage


    const isActive = (path) => {
        return location.pathname === path ? 'text-blue-400' : 'hover:text-blue-200';
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const handleLogout = () => {
        // Clear the user's authentication token
        localStorage.removeItem('authToken');
        localStorage.removeItem('name'); // Remove the userName from localStorage
        window.location.href = '/login'; // Redirect to login page or home page
    };

    return (
        <header className="shadow-md">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="#" className="text-3xl">
                            FacadeOption
                        </a>
                    </div>
                    <nav className="md:flex space-x-10">
                        <Link to="/" className={`text-base ${isActive('/')}`}>
                            Home
                        </Link>
                        <Link to="/App" className={`text-base ${isActive('/App')}`}>
                            App
                        </Link>
                        <Link to="/service" className={`text-base ${isActive('/service')}`}>
                            Services
                        </Link>
                        <Link to="/fofab" className={`text-base ${isActive('/fofab')}`}>
                            FOFab
                        </Link>
                        <Link to="/about" className={`text-base ${isActive('/about')}`}>
                            About
                        </Link>
                        <Link to="/project" className={`text-base ${isActive('/project')}`}>
                            Project
                        </Link>
                         <Link to="/miro" className={`text-base ${isActive('/miro')}`}>
                            Tutorial
                        </Link>
                        {userName ? (
                            <div className="relative">
                                <button
                                    onClick={handleDropdownToggle}
                                    className="text-base"
                                >
                                    {userName}
                                </button>
                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg py-1 z-50">
                                        <Link
                                            to="/profile"
                                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            onClick={() => setIsDropdownOpen(false)}
                                        >
                                            Profile
                                        </Link>
                                        <Link
                                            to={`/tmp`} // Use template literals to insert the userName variable
                                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            onClick={() => setIsDropdownOpen(false)}
                                        >
                                            Demo
                                        </Link>
                                        <button
                                            onClick={handleLogout}
                                            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                        >
                                            Logout
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Link to="/login" className={`text-base ${isActive('/login')}`}>
                                Login
                            </Link>
                        )}
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;
