import axios from 'axios';

const baseUrl = process.env.REACT_APP_SSO_API; // Base URL for your API

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to include the token in every request
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const post = (endpoint, body) => axiosInstance.post(endpoint, body);
export const get = (endpoint) => axiosInstance.get(endpoint);
export const put = (endpoint, body) => axiosInstance.put(endpoint, body);
export const del = (endpoint) => axiosInstance.delete(endpoint);
