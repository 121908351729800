import React from 'react';

const TermsConditionPage = () => {

    return (
        <div className="bg-gray-50 p-6 min-h-screen">
            <div className="max-w-screen-md mx-auto bg-white p-8 shadow-md rounded-md">
                <h1 className="text-3xl font-bold mb-6">Download and Copyright Ownership Agreement</h1>

                <p className="mb-4">This Agreement is made and entered </p>

                <p className="mb-4">
                    <strong>FacadeOption </strong>
                    and
                    <strong> User</strong>, an individual/entity downloading content from FacadeOption ("User").
                </p>

                <h2 className="text-xl font-semibold mb-2">1. License Grant</h2>
                <p className="mb-4">
                    The Company hereby grants the User a non-exclusive, non-transferable license to download and use the
                    materials
                    provided via the FacadeOption platform. The use of these materials is limited to personal or
                    internal business
                    purposes only and is subject to the terms and conditions of this Agreement.
                </p>

                <h2 className="text-xl font-semibold mb-2">2. Ownership of Copyright</h2>
                <p className="mb-4">
                    All intellectual property rights, including but not limited to copyrights, trademarks, and trade
                    secrets, in any
                    content, materials, designs, documents, models, or data ("Content") provided by FacadeOption to the
                    User through
                    download or any other means, are and shall remain the sole property of FacadeOption.
                </p>
                <p className="mb-4">
                    The User acknowledges that they do not gain any ownership rights to the downloaded Content and that
                    all rights,
                    title, and interest in and to the Content, including any derivative works created from it, are
                    retained by the Company.
                </p>

                <h2 className="text-xl font-semibold mb-2">3. Prohibited Use</h2>
                <ul className="list-disc list-inside mb-4 space-y-2">
                    <li>Sell, sublicense, distribute, or otherwise make the Content available to any third party without
                        the express written consent of FacadeOption.
                    </li>
                    <li>Modify, decompile, reverse-engineer, or create derivative works from the Content without
                        permission.
                    </li>
                    <li>Remove any proprietary notices or copyright labels from the Content.</li>
                </ul>

                <h2 className="text-xl font-semibold mb-2">4. Permitted Use</h2>
                <p className="mb-4">
                    The User is permitted to use the Content strictly for personal or internal use within the User’s
                    organization,
                    and for non-commercial purposes unless otherwise approved by the Company.
                </p>

                <h2 className="text-xl font-semibold mb-2">5. Termination</h2>
                <p className="mb-4">
                    This Agreement will remain in effect until terminated. FacadeOption reserves the right to terminate
                    this Agreement
                    immediately if the User breaches any of its terms. Upon termination, the User must cease all use of
                    the Content and
                    delete any copies in their possession.
                </p>

                <h2 className="text-xl font-semibold mb-2">6. No Warranty</h2>
                <p className="mb-4">
                    The Company provides the Content on an "as-is" basis and disclaims any warranties, express or
                    implied,
                    including but not limited to warranties of fitness for a particular purpose or non-infringement.
                </p>

                <h2 className="text-xl font-semibold mb-2">7. Limitation of Liability</h2>
                <p className="mb-4">
                    In no event shall FacadeOption be liable for any direct, indirect, incidental, consequential, or
                    punitive damages
                    arising out of the use of or inability to use the downloaded Content.
                </p>

                <h2 className="text-xl font-semibold mb-2">8. Governing Law</h2>
                <p className="mb-4">
                    This Agreement shall be governed by and construed in accordance with the laws of <strong>Philippines</strong>,
                    without regard to its conflict of law principles.
                </p>

                <h2 className="text-xl font-semibold mb-2">9. Entire Agreement</h2>
                <p>
                    This Agreement constitutes the entire understanding between the parties concerning the download and
                    use of Content,
                    superseding all prior discussions, agreements, or understandings of any kind.
                </p>
            </div>
        </div>
    );
};

export default TermsConditionPage;
