import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from '../service/httpService';

const VerifyEmailPage = () => {
    const { token } = useParams();
    const [notification, setNotification] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await get(`/sso/account/verify/${token}`);
                if (response.status === 200) {
                    setNotification({
                        type: 'success',
                        message: 'Email verified successfully! You can now log in.',
                    });
                    setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
                } else {
                    setNotification({
                        type: 'error',
                        message: 'Email verification failed. Please try again.',
                    });
                }
            } catch (error) {
                const errorMessage = error.response?.data?.detail || error.message || 'An unexpected error occurred';
                setNotification({
                    type: 'error',
                    message: errorMessage,
                });
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white shadow-lg -mt-52 rounded-lg">
                <h1 className="text-2xl font-bold text-center text-gray-900 mb-6">Verifying Email</h1>
                {notification && (
                    <div className={`p-4 mb-4 text-white ${notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'} rounded-md`}>
                        {notification.message}
                    </div>
                )}
                {/* Optionally add a loading spinner or message */}
            </div>
        </div>
    );
};

export default VerifyEmailPage;
