import React from 'react';

const GlassOutputTable = ({ result, glass_build }) => {
  if (!result) {
    return null; // Return nothing if there is no result data
  }

  // Helper function to apply green or red text based on the result value
  const getStatusColor = (value) => {
    return value === "Pass" ? "text-gray-900" : "text-red-600";
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Output</h2>
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="border px-4 py-2">Glass</th>
            <th className="border px-4 py-2 " colSpan="2">Stress Ratio</th>
            <th className="border px-4 py-2"  colSpan="2">Deflection</th>
            {/*<th className="border px-4 py-2">Recommended Thickness</th>*/}
          </tr>
        </thead>
        <tbody>
          {/* Display glass_1 Roark's Formula results */}
          <tr>
            <td className="border px-4 py-2" colSpan="6">
              <strong>Lite 1</strong>
            </td>
          </tr>

          {/* Display glass_1 BS6262_2005 results */}
          <tr>
            <td className="border px-4 py-2">BS6262</td>
            <td className="border px-4 py-2">{`${(parseFloat(result.glass_1.BS6262_2005[0]) * 100).toFixed(0)}%`}</td>
            <td className={`border px-4 py-2 ${getStatusColor(result.glass_1.BS6262_2005[1])}`}>
              {result.glass_1.BS6262_2005[1]}
            </td>
            <td className="border px-4 py-2">{`${(parseFloat(result.glass_1.BS6262_2005[2]) * 100).toFixed(0)}%`}</td>
            <td className={`border px-4 py-2 ${getStatusColor(result.glass_1.BS6262_2005[3])}`}>
              {result.glass_1.BS6262_2005[3]}
            </td>
            {/*<td className="border px-4 py-2 text-center">{parseFloat(result.glass_1.BS6262_2005[4]).toFixed(0)}</td>*/}
          </tr>

          {/* Display glass_1 AS1288_2006 results */}
          <tr>
            <td className="border px-4 py-2">AS1288</td>
            <td className="border px-4 py-2">{`${(parseFloat(result.glass_1.AS1288_2006[0]) * 100).toFixed(0)}%`}</td>
            <td className={`border px-4 py-2 ${getStatusColor(result.glass_1.AS1288_2006[1])}`}>
              {result.glass_1.AS1288_2006[1]}
            </td>
            <td className="border px-4 py-2">{`${(parseFloat(result.glass_1.AS1288_2006[2]) * 100).toFixed(0)}%`}</td>
            <td className={`border px-4 py-2 ${getStatusColor(result.glass_1.AS1288_2006[3])}`}>
              {result.glass_1.AS1288_2006[3]}
            </td>
            {/*<td className="border px-4 py-2 text-center">{parseFloat(result.glass_1.AS1288_2006[4]).toFixed(0)}</td>*/}
          </tr>

          <tr>
            <td className="border px-4 py-2 whitespace-nowrap">Roark's</td>
            <td className="border px-4 py-2">{`${(parseFloat(result.glass_1.roark_formula[0]) * 100).toFixed(0)}%`}</td>
            <td className={`border px-4 py-2 ${getStatusColor(result.glass_1.roark_formula[1])}`}>
              {result.glass_1.roark_formula[1]}
            </td>
            <td className="border px-4 py-2">{`${(parseFloat(result.glass_1.roark_formula[2]) * 100).toFixed(0)}%`}</td>
            <td className={`border px-4 py-2 ${getStatusColor(result.glass_1.roark_formula[3])}`}>
              {result.glass_1.roark_formula[3]}
            </td>
            {/*<td className="border px-4 py-2 text-center">{result.glass_1.roark_formula[4]}</td>*/}
          </tr>

          {/* Conditionally show glass_2 only if glass_build is not "Monolithic" */}
          {glass_build !== "Monolithic" && (
            <>
              <tr>
                <td className="border px-4 py-2" colSpan="6">
                  <strong>Lite 2</strong>
                </td>
              </tr>

              <tr>
                <td className="border px-4 py-2">BS6262</td>
                <td className="border px-4 py-2">{`${(parseFloat(result.glass_2.BS6262_2005[0]) * 100).toFixed(0)}%`}</td>
                <td className={`border px-4 py-2 ${getStatusColor(result.glass_2.BS6262_2005[1])}`}>
                  {result.glass_2.BS6262_2005[1]}
                </td>
                <td className="border px-4 py-2">{`${(parseFloat(result.glass_2.BS6262_2005[2]) * 100).toFixed(0)}%`}</td>
                <td className={`border px-4 py-2 ${getStatusColor(result.glass_2.BS6262_2005[3])}`}>
                  {result.glass_2.BS6262_2005[3]}
                </td>
                {/*<td className="border px-4 py-2 text-center">{parseFloat(result.glass_2.BS6262_2005[4]).toFixed(0)}</td>*/}
              </tr>
              <tr>
                <td className="border px-4 py-2">AS1288</td>
                <td className="border px-4 py-2">{`${(parseFloat(result.glass_2.AS1288_2006[0]) * 100).toFixed(0)}%`}</td>
                <td className={`border px-4 py-2 ${getStatusColor(result.glass_2.AS1288_2006[1])}`}>
                  {result.glass_2.AS1288_2006[1]}
                </td>
                <td className="border px-4 py-2">{`${(parseFloat(result.glass_2.AS1288_2006[2]) * 100).toFixed(0)}%`}</td>
                <td className={`border px-4 py-2 ${getStatusColor(result.glass_2.AS1288_2006[3])}`}>
                  {result.glass_2.AS1288_2006[3]}
                </td>
                {/*<td className="border px-4 py-2 text-center">{parseFloat(result.glass_2.AS1288_2006[4]).toFixed(0)}</td>*/}
              </tr>
              <tr>
                <td className="border px-4 py-2">Roark's</td>
                <td className="border px-4 py-2">{`${(parseFloat(result.glass_2.roark_formula[0]) * 100).toFixed(0)}%`}</td>
                <td className={`border px-4 py-2 ${getStatusColor(result.glass_2.roark_formula[1])}`}>
                  {result.glass_2.roark_formula[1]}
                </td>
                <td className="border px-4 py-2">{`${(parseFloat(result.glass_2.roark_formula[2]) * 100).toFixed(0)}%`}</td>
                <td className={`border px-4 py-2 ${getStatusColor(result.glass_2.roark_formula[3])}`}>
                  {result.glass_2.roark_formula[3]}
                </td>
                {/*<td className="border px-4 py-2 text-center">{result.glass_2.roark_formula[4]}</td>*/}
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GlassOutputTable;
