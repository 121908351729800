import React, { useState } from 'react';
import ReactFlow, {Controls,Background,Position,} from 'reactflow';
import 'reactflow/dist/style.css';
import { useNavigate } from 'react-router-dom';


const edges = [
{ id: '1-2', source: '1', target: '2', type: 'smoothstep',animated: true },
{ id: '1-2', source: '2', target: '3', type: 'smoothstep',animated: true },
{ id: '1-2', source: '2', target: '4', type: 'smoothstep',animated: true },

{ id: '1-2', source: '1', target: '5', type: 'smoothstep',animated: true },
{ id: '1-2', source: '5', target: '6', type: 'smoothstep',animated: true },
{ id: '1-2', source: '5', target: '7', type: 'smoothstep',animated: true },

{ id: '1-2', source: '1', target: '8', type: 'smoothstep',animated: true },
{ id: '1-2', source: '8', target: '9', type: 'smoothstep',animated: true },
{ id: '1-2', source: '8', target: '10', type: 'smoothstep',animated: true },

{ id: '1-2', source: '1', target: '11', type: 'smoothstep',animated: true },
{ id: '1-2', source: '11', target: '12', type: 'smoothstep',animated: true },
{ id: '1-2', source: '11', target: '13', type: 'smoothstep',animated: true },
{ id: '1-2', source: '11', target: '14', type: 'smoothstep',animated: true },
{ id: '1-2', source: '11', target: '15', type: 'smoothstep',animated: true },

{ id: '1-2', source: '1', target: '16', type: 'smoothstep',animated: true },
{ id: '1-2', source: '16', target: '17', type: 'smoothstep',animated: true },
{ id: '1-2', source: '16', target: '18', type: 'smoothstep',animated: true },
{ id: '1-2', source: '16', target: '19', type: 'smoothstep',animated: true },
{ id: '1-2', source: '16', target: '20', type: 'smoothstep',animated: true },
{ id: '1-2', source: '16', target: '21', type: 'smoothstep',animated: true },


{ id: '1-2', source: '50', target: '1', type: 'smoothstep',animated: true },
{ id: '1-2', source: '51', target: '50', type: 'smoothstep',animated: true },
{ id: '1-2', source: '52', target: '50', type: 'smoothstep',animated: true },

{ id: '1-2', source: '53', target: '1', type: 'smoothstep',animated: true },
{ id: '1-2', source: '54', target: '53', type: 'smoothstep',animated: true },
{ id: '1-2', source: '55', target: '53', type: 'smoothstep',animated: true },

{ id: '1-2', source: '56', target: '1', type: 'smoothstep',animated: true },
{ id: '1-2', source: '57', target: '56', type: 'smoothstep',animated: true },
{ id: '1-2', source: '58', target: '56', type: 'smoothstep',animated: true },
{ id: '1-2', source: '59', target: '56', type: 'smoothstep',animated: true },
{ id: '1-2', source: '60', target: '56', type: 'smoothstep',animated: true },

{ id: '1-2', source: '61', target: '1', type: 'smoothstep',animated: true },
{ id: '1-2', source: '62', target: '61', type: 'smoothstep',animated: true },
{ id: '1-2', source: '63', target: '61', type: 'smoothstep',animated: true },
{ id: '1-2', source: '64', target: '61', type: 'smoothstep',animated: true },
{ id: '1-2', source: '65', target: '61', type: 'smoothstep',animated: true },
{ id: '1-2', source: '66', target: '61', type: 'smoothstep',animated: true },
{ id: '1-2', source: '67', target: '61', type: 'smoothstep',animated: true },
];

const Service = [
  {
    id: '1',
    data: { label: 'Services' },
    position: { x: 650, y: 650 },
    style: { background: 'none', border: '2px solid red', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },

];

const DesignIntent = [
  {
    id: '2',
    data: { label: 'Design Intent' },
    position: { x: 400, y: 375 },
    style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
       sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '3',
    data: { label: 'Facade System Study' },
    position: { x: 150, y: 350 },
        style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px'},
        type: 'bidirectional',
     sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '4',
    data: { label: 'Facade System Proposal' },
    position: { x: 150, y: 400 },
        style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
     sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
];

const ShopDrawing = [
  {
    id: '5',
    data: { label: 'Shop Drawing' },
    position: { x: 400, y: 475 },
    style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
 sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '6',
    data: { label: 'Plan / Elevation / Section' },
    position: { x: 150, y: 450 },
        style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px', width:"180px" },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '7',
    data: { label: 'Facade Detail Drawing' },
    position: { x: 150, y: 500 },
        style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
];

const Engineering = [
  {
    id: '8',
    data: { label: 'Engineering' },
    position: { x: 400, y: 575 },
    style: { background: 'none', border: '2px solid #FC50E3', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
   sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '9',
    data: { label: 'Structural Analysis' },
    position: { x: 150, y: 550 },
        style: { background: 'none', border: '2px solid #FC50E3', color: 'white', borderRadius: '20px', width:"180px" },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '10',
    data: { label: 'Structural Report' },
    position: { x: 150, y: 600 },
        style: { background: 'none', border: '2px solid #FC50E3', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
];

const ThermalAnalysis = [
  {
    id: '11',
    data: { label: 'Thermal Analysis' },
    position: { x: 400, y: 725 },
    style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
 sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '12',
    data: { label: 'Parts U-Value' },
    position: { x: 150, y: 650 },
        style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px', width:"180px" },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '13',
    data: { label: 'Assembly U-Value' },
    position: { x: 150, y: 700 },
        style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
      {
    id: '14',
    data: { label: 'Project U-Value' },
    position: { x: 150, y: 750 },
        style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px', width:"180px" },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '15',
    data: { label: 'Condensation Study' },
    position: { x: 150, y: 800 },
        style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
];


const FacadeGeometryStudy = [
  {
    id: '16',
    data: { label: 'Facade Geometry Study' },
    position: { x: 400, y: 950 },
    style: { background: 'none', border: '2px solid #2BC5FE', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
 sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '17',
    data: { label: 'Complex Facade' },
    position: { x: 150, y: 850 },
        style: { background: 'none', border: '2px solid #2BC5FE', color: 'white', borderRadius: '20px', width:"180px" },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '18',
    data: { label: 'Optimization' },
    position: { x: 150, y: 900 },
        style: { background: 'none', border: '2px solid #2BC5FE', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
      {
    id: '19',
    data: { label: 'Panel Modulation' },
    position: { x: 150, y: 950 },
        style: { background: 'none', border: '2px solid #2BC5FE', color: 'white', borderRadius: '20px', width:"180px" },
        type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '20',
    data: { label: 'Facade Wireframe' },
    position: { x: 150, y: 1000 },
        style: { background: 'none', border: '2px solid #2BC5FE', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
         {
    id: '21',
    data: { label: 'Analysis + Report' },
    position: { x: 150, y: 1050 },
        style: { background: 'none', border: '2px solid #2BC5FE', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
];

const BIM = [
  {
    id: '50',
    data: { label: 'BIM' },
    position: { x: 900, y: 375 },
    style: { background: 'none', border: '2px solid #7B30FD', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '51',
    data: { label: 'Rhino3d to Revit | Archicad | IFC | Etc' },
    position: { x: 1100, y: 350 },
        style: { background: 'none', border: '2px solid #7B30FD', color: 'white', borderRadius: '20px', width:"250px" },
        type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
     {
    id: '52',
    data: { label: 'LOD 100 | 200 | 300 | 400g' },
    position: { x: 1100, y: 400 },
        style: { background: 'none', border: '2px solid #7B30FD', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
       sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
];


const FabricationDrawings = [
  {
    id: '53',
    data: { label: 'Fabrication Drawings' },
    position: { x: 900, y: 475 },
    style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '54',
    data: { label: 'Parts Fabrication Drawing' },
    position: { x: 1100, y: 450 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', width:"250px" },
        type: 'bidirectional',
        sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
     {
    id: '55',
    data: { label: 'Assembly Drawings' },
    position: { x: 1100, y: 500 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
];

const FacadeProcurement = [
  {
    id: '56',
    data: { label: 'Facade Procurement' },
    position: { x: 900, y: 625 },
    style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '57',
    data: { label: 'Material Approval' },
    position: { x: 1100, y: 550 },
        style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px', },
        type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
     {
    id: '58',
    data: { label: 'Material Sourcing' },
    position: { x: 1100, y: 600 },
        style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
      {
    id: '59',
    data: { label: 'Facade Contractor Sourcing' },
    position: { x: 1100, y: 650 },
        style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
       sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
      {
    id: '60',
    data: { label: 'Fabrication Sourcing' },
    position: { x: 1100, y: 700 },
        style: { background: 'none', border: '2px solid #FC6B40', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
];


const FacadeInspection = [
  {
    id: '61',
    data: { label: 'Facade Inspection' },
    position: { x: 900, y: 850 },
    style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: '62',
    data: { label: 'Material Factory' },
    position: { x: 1100, y: 750 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', },
        type: 'bidirectional',
      sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
     {
    id: '63',
    data: { label: 'Fabrication Factory' },
    position: { x: 1100, y: 800 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
      sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
      {
    id: '64',
    data: { label: 'Project Site' },
    position: { x: 1100, y: 850 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
      {
    id: '65',
    data: { label: 'Visual Mock-up' },
    position: { x: 1100, y: 900 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
       {
    id: '66',
    data: { label: 'Performance Mock-up' },
    position: { x: 1100, y: 950 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
       {
    id: '67',
    data: { label: 'Workmanship Mock-up' },
    position: { x: 1100, y: 1000 },
        style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px', width:"180px"  },
           type: 'bidirectional',
      sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
];

const nodes = [...Service, ...DesignIntent, ...ShopDrawing,...Engineering,...ThermalAnalysis,...FacadeGeometryStudy,...BIM,...FabricationDrawings,...FacadeProcurement,...FacadeInspection];
function Flow() {


  const [modalVisible, setModalVisible] = useState(false);
  const [hoveredNode, setHoveredNode] = useState(null);
  const navigate = useNavigate();

  const onNodeMouseEnter = (event, node) => {
    setHoveredNode(node);
    setModalVisible(true);
  };

  const onNodeMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };

  const onNodeClick = (event, node) => {
      if (node.href){
    navigate(`/${node.href}`);}
  };

  const onFlowMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };




  return (
    <div className="w-screen relative" style={{ height: 'calc(100vh - 85px)' , background:"#2c2c48"}}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
        onNodeClick={onNodeClick}
        onMouseLeave={onFlowMouseLeave}
        fitView={true}
      >
        {/*<Background />*/}
        <Controls />
      </ReactFlow>


    {modalVisible && hoveredNode && hoveredNode.data.content && (
        <div
          style={{
            position: 'absolute',
            top: 20,  // Offset for Y position
            right: 50, // Offset for X position
            padding: '10px',
            backgroundColor: 'white',
            border: '1px solid black',
            width: '300px',
            height: '500px',
            overflow: 'auto'
          }}
          dangerouslySetInnerHTML={{ __html: hoveredNode.data.content }}
        />
      )}
    </div>
  );
}

export default Flow;
