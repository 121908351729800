import React, { useState } from 'react';
import ReactFlow, {Controls,Background,Position,} from 'reactflow';
import 'reactflow/dist/style.css';
import { useNavigate } from 'react-router-dom';


const edges = [
{ id: '1-2', source: '2', target: '1', type: 'smoothstep',animated: true },
{ id: '1-2', source: '3', target: '1', type: 'smoothstep',animated: true },
{ id: '1-2', source: '4', target: '1', type: 'smoothstep',animated: true },
{ id: '1-2', source: '5', target: '1', type: 'smoothstep',animated: true },

{ id: '1-2', source: '1', target: '6', type: 'smoothstep',animated: true },
{ id: '1-2', source: '6', target: '7', type: 'smoothstep',animated: true },
{ id: '1-2', source: '7', target: '8', type: 'smoothstep',animated: true },

{ id: '1-2', source: '6', target: '9', type: 'smoothstep',animated: true },
{ id: '1-2', source: '9', target: '10', type: 'smoothstep',animated: true },
{ id: '1-2', source: '9', target: '11', type: 'smoothstep',animated: true },

{ id: '1-2', source: '9', target: '12', type: 'smoothstep',animated: true },
{ id: '1-2', source: '9', target: '13', type: 'smoothstep',animated: true },
{ id: '1-2', source: '10', target: '14', type: 'smoothstep',animated: true },
{ id: '1-2', source: '11', target: '14', type: 'smoothstep',animated: true },
{ id: '1-2', source: '12', target: '14', type: 'smoothstep',animated: true },
{ id: '1-2', source: '13', target: '14', type: 'smoothstep',animated: true },


{ id: '1-2', source: '6', target: '15', type: 'smoothstep',animated: true },
{ id: '1-2', source: '15', target: '16', type: 'smoothstep',animated: true },
{ id: '1-2', source: '15', target: '17', type: 'smoothstep',animated: true },
{ id: '1-2', source: '16', target: '18', type: 'smoothstep',animated: true },
{ id: '1-2', source: '17', target: '18', type: 'smoothstep',animated: true },

{ id: '1-2', source: '6', target: '19', type: 'smoothstep',animated: true },
{ id: '1-2', source: '6', target: '20', type: 'smoothstep',animated: true },
{ id: '1-2', source: '19', target: '21', type: 'smoothstep',animated: true },
{ id: '1-2', source: '20', target: '21', type: 'smoothstep',animated: true },


{ id: '1-2', source: '1', target: '23', type: 'smoothstep',animated: true },
{ id: '1-2', source: '23', target: '24', type: 'smoothstep',animated: true },
{ id: '1-2', source: '23', target: '25', type: 'smoothstep',animated: true },
{ id: '1-2', source: '23', target: '26', type: 'smoothstep',animated: true },

{ id: '1-2', source: '24', target: '27', type: 'smoothstep',animated: true },
{ id: '1-2', source: '25', target: '27', type: 'smoothstep',animated: true },
{ id: '1-2', source: '26', target: '27', type: 'smoothstep',animated: true },
];

const FOfab = [
  {
    id: '1',
    data: { label: 'FOfab' },
    position: { x: 250, y: 450 },
    style: { background: 'none', border: '2px solid red', color: 'white', borderRadius: '20px', },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,

  },
     {
    id: '2',
    data: { label: 'Rhino3d + Grasshoper' },
    position: { x: 150, y: 150 },
    style: { background: 'none', border: '2px solid #7B30FD', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Bottom,
    targetPosition: Position.Bottom,
  },
     {
    id: '3',
    data: { label: '2D shop drawing' },

    position: { x: 0, y: 400 },
    style: { background: 'none', border: '2px solid #FB2671', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '4',
    data: { label: 'Parts Information'},

    position: { x: 0, y: 450 },
    style: { background: 'none', border: '2px solid #3776FC', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },
     {
    id: '5',
    data: { label: 'General Information'},

    position: { x: 0, y: 500 },
    style: { background: 'none', border: '2px solid #FCAD1C', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  },



];

const FabricationModel3D = [
  {
    id: '6',
    data: { label: '3D Fabrication Model' },
    position: { x: 500, y: 350 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
      {
    id: '7',
    data: { label: 'LOD 100-400 model' },
    position: { x: 750, y: 150 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
      {
    id: '8',
    data: { label: 'To Revit | Archicad | IFC' },
    position: { x: 950, y: 150 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
       sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },

];

const Alumnium = [
      {
    id: '9',
    data: { label: 'Alumnium' },
    position: { x: 750, y: 275 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },

          {
    id: '10',
    data: { label: 'Cut Lengths' },
    position: { x: 950, y: 200 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
          {
    id: '11',
    data: { label: 'Cut' },
    position: { x: 950, y: 250 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
          {
    id: '12',
    data: { label: 'Notches' },
    position: { x: 950, y: 300 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
              {
    id: '13',
    data: { label: 'Holes' },
    position: { x: 950, y: 350 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
       sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
            {
    id: '14',
    data: { label: 'Aluminium Fabricator' },
    position: { x: 1150, y: 200 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    targetPosition: Position.Left,
    sourcePosition: Position.Left
  },

];

const Glass = [
      {
    id: '15',
    data: { label: 'Glass' },
    position: { x: 750, y: 425 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },

          {
    id: '16',
    data: { label: 'Dimensions' },
    position: { x: 950, y: 400 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
          {
    id: '17',
    data: { label: 'Angles' },
    position: { x: 950, y: 450 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
          {
    id: '18',
    data: { label: 'Glass Fabricator' },
    position: { x: 1150, y: 400 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },

];


const STPFiles = [
      {
    id: '19',
    data: { label: 'STP Files' },
    position: { x: 750, y: 525 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
     sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },

          {
    id: '20',
    data: { label: 'DXF' },
    position: { x: 750, y: 575 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
          {
    id: '21',
    data: { label: 'CNC Machine' },
    position: { x: 950, y: 525 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  },
          {
    id: '22',
    data: { label: 'Glass Fabricator' },
    position: { x: 1150, y: 400 },
    style: { background: 'none', border: '2px solid #61D236', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    targetPosition: Position.Left,
    sourcePosition: Position.Left
  },

];

const BillofMaterials = [
      {
    id: '23',
    data: { label: 'Bill of Materials' },
     position: { x: 500, y: 675 },
    style: { background: 'none', border: '2px solid #B63FFD', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },

          {
    id: '24',
    data: { label: 'Cut lenghts' },
  position: { x: 750, y: 650 },
    style: { background: 'none', border: '2px solid #B63FFD', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
          {
    id: '25',
    data: { label: 'Quantity' },
  position: { x: 750, y: 700 },
    style: { background: 'none', border: '2px solid #B63FFD', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
          {
    id: '26',
    data: { label: 'Parts Information' },
  position: { x: 750, y: 750 },
    style: { background: 'none', border: '2px solid #B63FFD', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
              {
    id: '27',
    data: { label: 'Procurement' },
    position: { x: 950, y: 650 },
    style: { background: 'none', border: '2px solid #B63FFD', color: 'white', borderRadius: '20px' },
    type: 'bidirectional',
     targetPosition: Position.Left,
    sourcePosition: Position.Left
  },

];


const nodes = [...FOfab, ...FabricationModel3D,...Alumnium,...Glass,...STPFiles,...BillofMaterials];
function Flow() {
  const [modalVisible, setModalVisible] = useState(false);
  const [hoveredNode, setHoveredNode] = useState(null);
  const navigate = useNavigate();

  const onNodeMouseEnter = (event, node) => {
    setHoveredNode(node);
    setModalVisible(true);
  };

  const onNodeMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };

const onNodeClick = (event, node) => {
      if (node.href){
    navigate(`/${node.href}`);}
  };

  const onFlowMouseLeave = () => {
    setModalVisible(false);
    setHoveredNode(null);
  };




  return (
    <div className="w-screen relative" style={{ height: 'calc(100vh - 85px)' , background:"#2c2c48"}}>

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
        onNodeClick={onNodeClick}
        onMouseLeave={onFlowMouseLeave}
        fitView={true}
      >
        {/*<Background />*/}
        <Controls />
      </ReactFlow>


    {modalVisible && hoveredNode && hoveredNode.data.content && (
        <div
          style={{
            position: 'absolute',
            top: 20,  // Offset for Y position
            right: 50, // Offset for X position
            padding: '10px',
            backgroundColor: 'white',
            border: '1px solid black',
            width: '300px',
            height: '500px',
            overflow: 'auto'
          }}
          dangerouslySetInnerHTML={{ __html: hoveredNode.data.content }}
        />
      )}
    </div>
  );
}

export default Flow;
