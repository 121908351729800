import React, {useEffect, useState} from 'react';
import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import MyPieChart from "./MyPieChart";

ChartJS.register(ArcElement, Tooltip, Legend);

const MaterialTakeOff = ({urlJson}) => {
    const calculateTotalPrice = (value, divisor) => {
        if (!isNaN(parseFloat(value)) && value !== null && value !== '') {
            const parsedValue = parseFloat(value);
            return Number.isInteger(parsedValue) ? parsedValue / divisor : (parsedValue / divisor).toFixed(2);
        } else {
            return 0;
        }
    };
    const [materials, setMaterials] = useState([]);
    const defaultPrices = {
        "G-01": 100,
        "I-01": 30,
        "A-01": 18.5,
        "A-02": 20.705,
        "A-03": 21.21,
        "A-04": 24.745,
        "A-21": 14.645,
        "A-22": 17.17,
        "A-12": 8.585,
        "A-31": 10.1,
        "A-32": 11.11,
        "F-01": 2,
        "F-02": 1,
        "F-11": 0.5,
        "F-12": 0.5,
        "F-13": 0.833,
        "M-01": .5,
        "M-02": .5,
        "M-03": .5,
        "M-11": .5,
        "M-12": .5,
        "M-41": 20,
        "M-42": 10,

        // Add more Part_Code and their default prices here as needed
    };
    useEffect(() => {
        const fetchMaterials = async () => {
            try {
                const response = await fetch(`${urlJson}`);
                const data = await response.json();
                // Ensure each material has a default price and weight if not provided
                const materialsWithDefaults = data.map(material => {
                    let total_price = 0;
                    let value = 0; // Initialize value variable
                    let price = material.price || defaultPrices[material.part_code] || 0
                    console.log(material.unit + " /" + material.quantity + " /" + price)
                    switch (material.unit) {
                        case "sq.m":
                            value = material.area / 1000000; // Calculate value based on area
                            total_price = value * material.quantity * price;
                            break;
                        case "mm":
                            value = material.length / 1000; // Calculate value based on length
                            total_price = value * material.quantity * price;
                            break;
                        case "pcs":
                            value = NaN
                            total_price = material.quantity * price; // Calculate total price directly for pieces
                            break;
                        default:
                            total_price = 0;
                    }

                    return {
                        ...material,
                        value: value,
                        price: price,
                        weight: material.weight || 0,
                        total_price: total_price,
                    };
                });
                // Define the desired category order
                const categoryOrder = [
                    "Glass",
                    "Insulation",
                    "Aluminium Extrusion",
                    "Fastener",
                    "Miscellaneous",

                    // Add other categories here
                ];

                // Sort materials according to categoryOrder and then by part_code within each category
                const sortedMaterials = [...materialsWithDefaults].sort((a, b) => {
                    // Get the index of categories in categoryOrder
                    const indexA = categoryOrder.indexOf(a.category);
                    const indexB = categoryOrder.indexOf(b.category);

                    // If categories are not found in categoryOrder, place them at the end
                    if (indexA === -1 && indexB === -1) {
                        return 0; // Maintain current order
                    } else if (indexA === -1) {
                        return 1; // Place b after a
                    } else if (indexB === -1) {
                        return -1; // Place a before b
                    }

                    // Sort by categoryOrder first
                    if (indexA !== indexB) {
                        return indexA - indexB;
                    }

                    // Within the same category, sort by part_code
                    return a.part_code.localeCompare(b.part_code);
                });

                setMaterials(sortedMaterials);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error fetching data
            }
        };

        fetchMaterials();
    }, []);

    const handlePriceChange = (id, newPrice) => {
        const updatedMaterials = materials.map(material => {
            if (material.foid === id) {
                const {unit, area, length, quantity} = material;
                let value;
                let total_price;

                // Calculate value and total_price based on the unit
                switch (unit) {
                    case "sq.m":
                        value = area / 1000000; // Calculate value based on area
                        total_price = value * quantity * newPrice; // Use newPrice
                        break;
                    case "mm":
                        value = length / 1000; // Calculate value based on length
                        total_price = value * quantity * newPrice; // Use newPrice
                        break;
                    case "pieces":
                        value = NaN; // No calculation needed for pieces
                        total_price = quantity * newPrice; // Calculate total price directly for pieces
                        break;
                    default:
                        total_price = 0; // Default total price if no matching unit
                }

                // Return updated material with new price and calculated total_price
                return {...material, price: newPrice, total_price};
            }
            return material; // Return unchanged material
        });

        setMaterials(updatedMaterials);
    };


    const getTotalPricePerPanel = () => {
        return materials.reduce((total, material) => {
            return total + (material.total_price);
        }, 0).toFixed(2);
    };


    const chartWeightData = {
        labels: materials.map(material => material.description),
        datasets: [
            {
                label: 'Total Price',
                data: materials.map(material => (material.quantity * material.weight).toFixed(2)),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="mx-auto p-4">
            <div className="flex  space-x-4  ">
                <div className="">
                    <table className="table-auto bg-white  w-full ">
                        <thead className="bg-primary-color text-white">
                        <tr>
                            <th className="px-4 py-1 text-left">Category</th>
                            <th className="px-4">Code</th>
                            <th className="px-4 text-left">Name</th>
                            <th className="px-4text-center">Qty</th>
                            <th className="px-2 text-right">Value</th>
                            <th className="px-2 text-center">Unit</th>
                            <th className="px-4 text-center whitespace-nowrap">Price(per unit)</th>
                            <th className="px-4">Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        {materials.map(material => (
                            <tr key={material.foid} className="border-b">
                                <td className="px-4 py-1 whitespace-nowrap">{material.category}</td>
                                <td className="px-4  whitespace-nowrap">{material.part_code}</td>
                                <td className="px-4 whitespace-nowrap  ">{material.description}</td>
                                <td className="px-4  text-center">{material.quantity}</td>
                                <td className="px-2 w-20 text-right">
                                  {isNaN(material.value) || material.value === 0 || material.value === 0.000 ? '' : material.value.toFixed(3)}
                                </td>
                                <td className="px-2 text-center ">  {material.unit === "mm" ? "m" : material.unit}</td>
                                <td className=" text-right w-20">
                                    <input
                                        type="number"
                                        value={material.price}
                                        onChange={(e) => handlePriceChange(material.foid, parseFloat(e.target.value))}
                                        className="border rounded px-2  w-full text-right"
                                    />
                                </td>
                                <td className="px-4  text-right">
                                    {material.total_price.toFixed(2)}
                                </td>
                            </tr>
                        ))}
                        <tr className="border-t ">
                            <td colSpan="7" className="text-right px-4 py-2">Total :</td>
                            <td className="px-4  text-right whitespace-nowrap">${getTotalPricePerPanel()}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <div className="p-10 " style={{height: '600px', width: '600px'}}>
                    <MyPieChart materials={materials}/>
                </div>
            </div>

        </div>
    );
};

export default MaterialTakeOff;
