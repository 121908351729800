import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';

const FileListPage = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Define the base URL and endpoint
                const baseUrl = process.env.REACT_APP_PROCESSOR_API; // Use your actual processor URL here
                const endpoint = '/project/';

                // Retrieve the token from localStorage
                const token = localStorage.getItem('authToken');

                // Set up axios request with authorization header
                const response = await axios.get(`${baseUrl}${endpoint}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                setData(response.data);
            } catch (error) {
                toast.error(error.message || 'An error occurred while fetching data.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        };

        fetchData();
    }, []);

    const handleDelete = async (projectId) => {
        try {
            // Define the delete URL
            const baseUrl = process.env.REACT_APP_PROCESSOR_API; // Use your actual processor URL here
            const url = `${baseUrl}/project/${projectId}`;

            // Retrieve the token from localStorage
            const token = localStorage.getItem('authToken');

            // Make the delete request
            await axios.delete(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            // Remove the deleted project from the state
            setData(data.filter(item => item.id !== projectId));

            toast.success('Project deleted successfully', {
                position: 'top-right',
                autoClose: 5000,
            });
        } catch (error) {
            toast.error(error.message || 'An error occurred while deleting the project.', {
                position: 'top-right',
                autoClose: 5000,
            });
        }
    };

    return (
        <div className="flex flex-col items-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-2xl bg-white shadow-md rounded-lg p-6">
                {data ? (
                    <div>
                        <h1 className="text-3xl font-bold text-gray-900 mb-4">Projects</h1>
                         <p className="text-red-500 font-medium mb-4">Files will be automatically deleted after 1 hour.</p>
                        <div className="space-y-4">
                            {data.map(item => (
                                <div key={item.id}
                                     className="flex justify-between items-center bg-gray-50 p-4 rounded border border-gray-200 mb-4">
                                    <Link
                                        to={`/tmp/${item.name}`}
                                        className="text-xl font-semibold text-gray-800"
                                    >
                                        {item.name}
                                    </Link>
                                    <button
                                        onClick={() => handleDelete(item.id)}
                                        className="text-white bg-red-500 hover:bg-red-700 font-semibold py-2 px-4 rounded shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1"
                                    >
                                        Delete
                                    </button>
                                </div>
                            ))}
                        </div>

                    </div>
                ) : (
                    <div>  <h1 className="text-3xl font-bold text-gray-900 mb-4">Projects</h1>
                        </div>
                )}
            </div>
        </div>
    );
};

export default FileListPage;
