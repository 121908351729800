import React from 'react';
import Flow from "../components/Flow";


const HomePage = () => {

    return (
    <div >
        <Flow/>
    </div>
    );
};

export default HomePage;
