import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../service/httpService'; // Adjust the import path as needed
import { toast } from 'react-toastify';

const ProfilePage = () => {
    const { userId } = useParams(); // Assumes you are passing userId as a URL parameter
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Retrieve token from cookies
                const authToken =localStorage.getItem('authToken');
                setToken(authToken || 'No token found');

                // Fetch user data from the API
                const response = await get(`/sso/account/`); // Adjust endpoint if necessary
                const userData = response.data; // Access the 'data' field
                console.log('Fetched user data:', userData); // Debugging
                setUser(userData);

            } catch (error) {
                toast.error(error.message || 'An error occurred while fetching user data.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        };

        fetchUserData();
    }, [userId]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(token).then(() => {
            toast.success('Token copied to clipboard!', {
                position: 'top-right',
                autoClose: 3000,
            });
        }).catch((error) => {
            toast.error('Failed to copy token.', {
                position: 'top-right',
                autoClose: 3000,
            });
        });
    };

    // Ensure user and user.licenses are defined
    const licenses = user?.licenses || [];

    return (
        <div className="flex flex-col items-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-2xl bg-white shadow-md rounded-lg p-6">
                {user ? (
                    <>
                        <h1 className="text-3xl font-bold text-gray-900 mb-4">{user.name}</h1>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Licenses</h2>
                        {licenses.length > 0 ? (
                            <div>
                                {licenses.map((license) => (
                                    <div key={license.id} className="text-gray-700 mb-2">
                                        <div>Name: {license.name}</div>
                                        <div>Status: {license.status ? 'Active' : 'Inactive'}</div>
                                        {license.expiration_date && (
                                            <div>Expiration: Until further notice</div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-600">No licenses found.</p>
                        )}
                    </>
                ) : (
                    <p className="text-gray-600">Loading...</p>
                )}
                   {token && (
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold text-gray-800 mb-2">Token</h2>
                        <div className="flex items-center space-x-2">
                            <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap bg-gray-100 p-2 rounded border border-gray-300">{token}</p>
                            <button
                                onClick={copyToClipboard}
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePage;
